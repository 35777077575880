import { createStore, applyMiddleware, compose } from 'redux';
import { createLogger } from 'redux-logger';
import rootReducer from './reducers/root';
import apiMiddleware from './middlewares/api';
import search from './middlewares/search';
import searchId from './middlewares/search-id';
import search_query from './middlewares/search-query';
import searchAnalyticsMiddleware from './middlewares/search-analytics';
import relatedQueriesMiddleware from './middlewares/related-queries';
import brandingAssetsMiddleware from './middlewares/branding-side-rail';
import { getWindow } from './utils/general';
import config from './config';
import ReduxThunk from 'redux-thunk';

const middlewares = [
  apiMiddleware,
  searchId,
  search_query,
  searchAnalyticsMiddleware,
  search,
  relatedQueriesMiddleware,
  brandingAssetsMiddleware,
  ReduxThunk,
];

const isClient = typeof window !== 'undefined';

if (config.NODE_ENV !== 'production' && isClient) {
  middlewares.push(createLogger({ collapsed: true })); // for debugging redux actions, writes to console
}

let preloadedState = {};
/* eslint-disable no-underscore-dangle */
if (getWindow().__PRELOADED_STATE__) {
  preloadedState = getWindow().__PRELOADED_STATE__;
  delete getWindow().__PRELOADED_STATE__;
}
const composeEnhancers = getWindow().__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
/* eslint-enable no-underscore-dangle */

export const getNewStore = () =>
  createStore(rootReducer, preloadedState, composeEnhancers(applyMiddleware(...middlewares)));

const store = getNewStore();

export default store;
