import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import SearchBoxIcons from '../../components/search-box/search-box-icons';

class SubmitButton extends React.Component {
  render() {
    return (
      <Submit type="submit" className="ais-SearchBox__submit" onClick={this.props.onClick} aria-label="Submit Search">
        {
          <span>
            <Text>Search</Text>
            <Icon className="fa" />
            <SearchBoxIcons />
          </span>
        }
      </Submit>
    );
  }
}

const Submit = styled.button`
  background: ${({ theme }) => theme.buttonBlue};
  position: absolute;
  top: 0;
  z-index: 2;
  right: 0;
  margin: 0;
  border: 0;
  border-radius: 0px 25px 25px 0px;
  padding: 0px 27px;
  height: 100%;
  vertical-align: middle;
  text-align: center;
  font-size: inherit;
  user-select: none;
  cursor: pointer;
  color: white;
  font-size: 14px;
  font-family: 'Lab Grotesque', sans-serif;
  line-height: 1.4;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 900;
  font-style: normal;
  @media (min-width: 1150px) {
    display: none;
  }
`;

const Text = styled.span`
  display: none;

  @media (min-width: 1150px) {
    display: block;
  }
`;

const Icon = styled.i`
  display: block;
  position: absolute;
  top: 12px;
  left: 9px;
  z-index: 10;
  color: white;
  border: none;
  vertical-align: middle;
  visibility: visible;
  margin-left: 6px;
  padding-top: 2px;
  cursor: pointer;

  &:before {
    content: '\f002';
    font-weight: 900;
    font-family: Font Awesome 5 Free;
    font-size: 125%;
  }

  @media (min-width: 1150px) {
    display: none;
  }
`;

SubmitButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default SubmitButton;
