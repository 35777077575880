import 'core-js/es6/set';
import 'core-js/es6/map';
import 'core-js/fn/string/includes';
import 'core-js/fn/array/fill';
import 'core-js/fn/object/entries';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import config from './config';

import Root from './components/root';

import { isOnBrowser } from './utils/general';

import store from './store';
import theme from './constants/theme';

import { unregister } from './registerServiceWorker';

import './index.css';

import '@fortawesome/fontawesome-free/css/all.css';
// for tooltip arrow in title.js
import 'react-popper-tooltip/dist/styles.css';

import { datadogRum } from '@datadog/browser-rum';

// eslint-disable-next-line
if (config.PUBLIC_PATH) __webpack_public_path__ = config.PUBLIC_PATH;

if (config.DD_ENABLED === 'true') {
  datadogRum.init({
    applicationId: config.DD_APPLICATION_ID,
    clientToken: config.DD_CLIENT_TOKEN,
    site: 'datadoghq.com',
    service: config.DD_SERVICE,
    env: config.DD_ENV,
    version: config.DD_VERSION,
    sampleRate: isNaN(config.DD_BROWSER_SAMPLE_RATE) ? 0 : Number(config.DD_BROWSER_SAMPLE_RATE),
    sessionReplaySampleRate: 0,
    trackInteractions: false,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
    allowedTracingOrigins: [config.DD_ALLOWED_TRACING_ORIGIN],
    tracingSampleRate: isNaN(config.DD_TRACE_SAMPLE_RATE) ? 0 : Number(config.DD_TRACE_SAMPLE_RATE),
  });
}

if (isOnBrowser()) {
  // for analytics
  var analyticsUrl = config.ANALYTICS_SERVICE_URL;
  if (!analyticsUrl) {
    analyticsUrl = 'https://www.preview1.emarketer.com/analytics/dist/harvester.min.js';
  }

  (function (b, e) {
    var c =
      (window.iiAna =
      window.iiAnallfuntion =
        function () {
          (c.q = c.q || []).push(arguments);
        });
    var a = document.createElement(b);
    a.type = 'text/javascript';
    a.src = e;
    a.sync = !0;
    var d = document.getElementsByTagName(b)[0];
    d.parentNode.insertBefore(a, d);
  })('script', analyticsUrl);
  window.iiAna('page');
}

ReactDOM.hydrate(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <Root />
    </ThemeProvider>
  </Provider>,
  document.getElementById('root')
);

unregister();
