import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import TypeAhead from './TypeAhead';
import { getFiltersByType } from '../../../selectors/facets';
import { connect } from 'react-redux';

const SearchTypeAhead = (props) => {
  const { type, filter, facetsLvl0, facetsLvl1, facetsLvl2 } = props;

  const inputRef = useRef(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [searching, setSearching] = useState(false);

  useEffect(() => {
    if (searchTerm === '' && searching) {
      setSearching(false);
    } else if (searchTerm.length > 0 && searching === false) {
      setSearching(true);
    }
  }, [searchTerm, searching]);

  const facetList = () => {
    const levelFacetsLvl0 = facetsLvl0[Object.keys(facetsLvl0)[0]];
    const levelFacetsLvl1 = facetsLvl1[Object.keys(facetsLvl1)[0]];
    const levelFacetsLvl2 = facetsLvl2[Object.keys(facetsLvl2)[0]];

    var allFacets = Object.assign({}, levelFacetsLvl0, levelFacetsLvl1, levelFacetsLvl2);
    return allFacets;
  };

  return (
    <SearchbarWrapper>
      <i style={{ position: 'absolute', top: '37px', left: '34px', color: '#007EFF' }} class="fas fa-search"></i>
      <TypeAhead
        inputRef={inputRef}
        list={facetList()}
        filter={filter}
        type={type}
        inputValue={searchTerm}
        onChangeFunc={setSearchTerm}
        className={searching ? `facet-search__input_searching` : `facet-search__input`}
        placeHolder={`Search`}
        wrapperClassName={`facet-search__input-wrapper`}
      />
    </SearchbarWrapper>
  );
};

export const SearchbarWrapper = styled.div`
  padding-bottom: 20px;
`;

SearchTypeAhead.propTypes = {
  type: PropTypes.string.isRequired,
  filter: PropTypes.any.isRequired,
  facetsLvl0: PropTypes.object,
  facetsLvl1: PropTypes.object,
  facetsLvl2: PropTypes.object,
};

const mapStateToProps = (state, ownProps) => ({
  filter: getFiltersByType(ownProps.type)(state),
});

export default connect(mapStateToProps)(SearchTypeAhead);
