import * as AT from '../constants/action-types';
import { constructSearchAnalyticsEventData } from '../utils/analytics';
import { makeAssetIds, makeAssetLabels } from '../utils/analytics';

const searchAnalyticsMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    /**
     * This analytics event should be fired if:
     *
     * 1. We're client-side
     * 2. The user is search (successfully or not [it _seems_ like we get search failures if things like GAM or any of the multitude of analytics we load have problems])
     *      or (pre)viewing an asset
     *
     * SHORT-TERM-FIX
     * We should probably fix this so that search isn't considered a failure, long-term, but this is a very easy win to fix the missing analytics.
     */
    if (
      action.type !== AT.SEARCH.SUCCESS &&
      action.type !== AT.SEARCH.FAILURE &&
      action.type !== AT.PREVIEW_ASSET &&
      action.type !== AT.VIEW_ASSET
    ) {
      return;
    }

    if (typeof window === 'undefined' || !window.iiAna) {
      return;
    }

    const state = getState();

    var searchAnalyticsEvent = constructSearchAnalyticsEventData(state);
    searchAnalyticsEvent.excludeFromTracking = ['Omniture', 'OmniturePublic'];
    switch (action.type) {
      case AT.PAGE_LOADED:
        searchAnalyticsEvent.source.app = state.source.app;
        searchAnalyticsEvent.source.location = state.source.location;
        break;
      case AT.PREVIEW_ASSET:
        searchAnalyticsEvent.preview = action.payload;
        break;
      case AT.VIEW_ASSET:
        searchAnalyticsEvent.click = action.payload;
        break;
      case AT.SEARCH.SUCCESS:
        searchAnalyticsEvent.engine.ix = action.payload.indexVersion;
        searchAnalyticsEvent.totalHits = action.payload.totalHits;
        searchAnalyticsEvent.results = makeAssetIds(action.payload.hits);
        searchAnalyticsEvent.resultLabels = makeAssetLabels(action.payload.hits);
        break;
      default:
        break;
    }

    window.iiAna('track', 'Search', searchAnalyticsEvent);
  };

export default searchAnalyticsMiddleware;
