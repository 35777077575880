import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';

import config from '../config';
import { setSearchQuery, submitSearchQuery } from '../actions/current-search-query';
import { setInitialSearchQueryFromUrlParams } from '../actions/search';

import SearchBoxIcons from '../components/search-box/search-box-icons';
import SubmitButton from '../components/search-box/search-box-submit';
import { setSearchQueryFromUrl, getQueryParameterByName } from '../utils/search';
import { isOnBrowser } from '../utils/general';
import { LoadRemoteComponent } from '@insiderintelligence/componentlibrary';
import { AppContext } from './AppContext';

class SearchBox extends React.Component {
  static contextType = AppContext;
  constructor() {
    super();
    this.state = {
      notDelayedSearchBoxValue: '',
      cursorTimerState: 0,
      showSvg: false,
      searchIconActive: false,
      truncatePlaceHolderText: false,
    };
  }

  componentDidMount() {
    this.setState({
      notDelayedSearchBoxValue: getQueryParameterByName('query'),
      showSvg: true,
      truncatePlaceHolderText: window.innerWidth < 1120,
    });

    if (isOnBrowser() && !getQueryParameterByName('query')) {
      this.props.setSearchQuery('');
    }

    this.props.setInitialSearchQueryFromUrlParams(setSearchQueryFromUrl(window.location.search));
  }

  resetSearchQuery = () => {
    this.setState({
      notDelayedSearchBoxValue: '',
    });
    this.props.setSearchQuery('');
  };

  onKeyPress = (event) => {
    if (event.which === 13 && !!this.props.currentSearchQuery) {
      event.preventDefault();
      this.submitSearch();
    }
  };

  onSearchQueryChange = (val, triggerSearch) => {
    this.setState({
      notDelayedSearchBoxValue: val,
      searchIconActive: val && val.length > 0,
    });

    if (triggerSearch) {
      this.submitSelectedSearch(val);
    }
  };

  clickSubmit = (event) => {
    event.preventDefault();
    this.submitSearch();
  };

  submitSearch = () => {
    this.props.setSearchQuery(this.state.notDelayedSearchBoxValue);
    this.props.currentSearchQuery && this.props.submitSearchQuery(this.props.currentSearchQuery);
    window.scrollTo(0, 0); // scroll to top on search
  };

  submitSelectedSearch = (val) => {
    this.props.setSearchQuery(val);
    this.props.submitSearchQuery(val);
  };

  render() {
    const searchIconColor = this.state.searchIconActive ? '#007EFF' : '#007EFF';
    const placeholderText = this.state.truncatePlaceHolderText
      ? 'Search'
      : `Search for reports, forecasts, charts, trends, and more`;
    const typeAheadProps = {
      inputvalue: this.state.notDelayedSearchBoxValue,
      onChangeFunc: this.onSearchQueryChange,
      className: `search-menu__input${``}`,
      placeHolder: placeholderText,
      wrapperClassName: 'search-menu__input-wrapper',
    };

    const searchBox = () => (
      <StyledSearch>
        <StyledForm onKeyPress={this.onKeyPress}>
          <Icon
            className="fa"
            searchIconColor={searchIconColor}
            onClick={this.clickSubmit}
            data-analytics="Search-Results-Icon-Submit"
          />
          <SubmitButton onClick={this.clickSubmit} data-analytics="Search-Results-Submit" />

          <StyledSearchInputContainer id="emarketer-typeahead-host">
            <LoadRemoteComponent props={typeAheadProps} url={config.COMMON_TYPEAHEAD_SERVICE_URL} name="typeahead">
              <input
                value={this.state.notDelayedSearchBoxValue}
                onChange={(event) => this.onSearchQueryChange(event.target.value, false)}
              />
            </LoadRemoteComponent>
          </StyledSearchInputContainer>
          <SearchBoxIcons />
        </StyledForm>
      </StyledSearch>
    );

    return searchBox();
  }
}

SearchBox.propTypes = {
  setInitialSearchQueryFromUrlParams: PropTypes.func.isRequired,
  setSearchQuery: PropTypes.func.isRequired,
  submitSearchQuery: PropTypes.func.isRequired,
  currentSearchQuery: PropTypes.string.isRequired,
  numberOfResults: PropTypes.number.isRequired,
  mobile: PropTypes.bool,
};

const Icon = styled.i`
  display: none;
  position: absolute;
  top: 10px;
  left: 9px;
  z-index: 10;
  color: ${({ searchIconColor }) => searchIconColor};
  border: none;
  vertical-align: middle;
  visibility: visible;
  margin-left: 6px;
  padding-top: 2px;
  cursor: pointer;
  &:before {
    content: '\f002';
    font-family: Font Awesome 5 Free;
    font-weight: 900;
    font-size: 125%;
  }
  @media (min-width: 1024px) {
    display: block;
  }
`;

const StyledSearch = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  cursor: pointer;
  box-sizing: border-box;
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: 1;
  }
  margin: 0 auto;
  @media (min-width: 1024px) {
    margin: 0px 30px;
  }
`;

const StyledForm = styled.form`
  position: relative;
  /* This is a CSS property that makes the element take up the entire width of its parent. */
  display: flex;
  align-items: center;
  background-color: #ebebeb;
  border-radius: 25px;
  height: 45px;
  width: 100%;
  width: -moz-available; /* WebKit-based browsers will ignore this. */
  width: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
  width: stretch;
  -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
  box-shadow: inset 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
  box-sizing: border-box;
  input {
    color: #111111;
  }
  @media (min-width: 1024px) {
    align-items: center;
  }
`;

const StyledSearchInputContainer = styled.div`
  width: 100%;
  position: relative;
  .search-menu__input-wrapper {
    width: 100%;
    float: left;
    display: block !important;
    position: relative;
  }
  input {
    border-radius: 25px;
    -webkit-box-shadow: none;
    box-shadow: none;
    padding: 0 55px 0 10px;
    height: 45px;
    width: 100%;
    font-size: 16px;
    -webkit-appearance: none;
    background-color: #ebebeb;
    color: #333;
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    white-space: nowrap;
    border: none;
    -webkit-box-shadow: inset 0 0 10px 0 rgb(0 0 0 / 5%);
    box-shadow: inset 0 0 10px 0 rgb(0 0 0 / 5%);
    float: left;
    ::placeholder {
      color: #777777;
    }
    :focus-visible {
      outline: none;
    }
    :focus {
      outline: none;
    }
    @media (min-width: 1024px) {
      padding: 0px 10px 0px 45px;
    }
  }
  .autocomplete__results-backdrop {
    background-image: -webkit-gradient(linear, left top, right top, from(#ebebeb), to(#007eff)) !important;
    background-image: linear-gradient(90deg, #ebebeb, #007eff) !important;
  }
  .autocomplete__menu {
    border-radius: 0 0 25px 25px;
    box-shadow: 0 12px 12px rgb(0 0 0 / 20%);
    background-color: #ffffff;
    padding: 0;
    position: absolute;
    z-index: 3;
    left: 0;
    right: 0;
    top: 45px;
    overflow-y: hidden;
  }

  @media (min-width: 1024px) {
    .autocomplete__item {
      padding: 10px 45px 10px 45px;
    }
    .autocomplete__item span {
      font-size: 16px;
    }
    .autocomplete__item:first-of-type {
      padding: 33px 45px 8px 45px !important;
    }
    .autocomplete__results-backdrop {
      background-image: none !important;
      background-color: #e5e5e5;
      position: absolute;
      left: 0;
      right: 0;
      z-index: -1;
      margin: 0;
    }
  }
`;

const mapStateToProps = ({ currentSearchQuery, searchResults }) => ({
  currentSearchQuery,
  numberOfResults: searchResults.numberOfResults,
});

export default connect(mapStateToProps, {
  submitSearchQuery,
  setSearchQuery,
  setInitialSearchQueryFromUrlParams,
})(SearchBox);
