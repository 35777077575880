import { handleActions } from 'redux-actions';
import * as AT from '../../constants/action-types';

let initialSearchHappened = false;

const initialState = {
  indexVersion: null,
  results: [],
  numberOfResults: 0,
  numberOfPages: 0,
  initialSearchHappened: false,
  didYouMean: null,
};

const elasticsearchResultsReducer = handleActions(
  {
    [AT.SEARCH.SUCCESS]: (state, { payload }) => {
      const newState = {
        indexVersion: payload.indexVersion,
        results: payload.hits,
        numberOfResults: payload.totalHits,
        numberOfPages: payload.numberOfPages,
        initialSearchHappened,
        didYouMean: payload.didYouMean,
      };

      initialSearchHappened = true;

      return newState;
    },
  },
  initialState
);

export default elasticsearchResultsReducer;
