import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Pagination from 'react-paginate';
import { setPage } from '../../actions/page';

import { buildSearchQueryString } from '../../utils/query';
import config from '../../config';
import { scrollToTop } from '../../utils/animations';
import { RESULTS_PER_PAGE } from '../../constants/query';
import { fetchAds } from '../../middlewares/ad';

class SearchResultsPagination extends React.Component {
  handlePageChange(index) {
    this.props.setPage(index);

    scrollToTop();
  }

  render() {
    const { query, facetsFilters, numericFilters, sortingOption, page, numberOfPages } = this.props;

    const urlGenerator = (i) =>
      i === page
        ? '#'
        : `${config.SEARCH_BASE_URL}?${buildSearchQueryString({
            query,
            facetsFilters,
            numericFilters,
            sortingOption,
            page: i,
          })}`;

    if (numberOfPages < 1) {
      return null;
    }

    return (
      <Container>
        <Pagination
          containerClassName="pagination"
          hrefBuilder={urlGenerator}
          itemsCountPerPage={RESULTS_PER_PAGE}
          pageCount={numberOfPages > 1000 / RESULTS_PER_PAGE ? 1000 / RESULTS_PER_PAGE : numberOfPages}
          forcePage={page - 1 < 0 ? 0 : page - 1}
          pageRangeDisplayed={7}
          marginPagesDisplayed={1}
          onPageChange={(i) => {
            this.handlePageChange(i.selected + 1, RESULTS_PER_PAGE);
          }}
          previousLabel="‹"
          nextLabel="›"
          pageClassName="link"
          activeClassName="activePage"
          previousClassName="link"
          nextClassName="link"
          previousLinkClassName="hrefPrev"
          nextLinkClassName="hrefNext"
          disableInitialCallback={true}
        />
      </Container>
    );
  }
}

const Container = styled.div`
  margin-bottom: 25px;

  font-family: 'Lab Grotesque', sans-serif;
  line-height: 1.4;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 800;
  font-style: normal;
  
  .pagination {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 0;
    
    -webkit-margin-before:0;
    -webkit-margin-after:0;
  
    .link {
      text-align: center;
      box-sizing: border-box;
      cursor: pointer;
      display: inline-block;
      padding: 3px;
      width: 4%;
      min-width: 25px;
      border-radius: 4px;
      font-size: 16px;
     
      &.activePage {
        a {
          color: black;
        }
      }
     
      a {
        text-decoration: none;
        color: #2b7cac;
        display: inline-block;
        position: relative;
        z-index: 1;
        padding: 50%;
        margin: -50%;
      }
  
      &:hover {
        background: rgba(39,81,175,.1);
        text-decoration: none;
                                
        a {
          color: #0275d8;
        }
       }
    
      &:focus {
        color: red;
        text-decoration: none;
      }
      
      &.disabled {
        cursor: default;
      
      a {
         color: black;
         cursor: default;
       }
      
        &:hover {
          background-color: transparent;
        }
    }
  }
`;

SearchResultsPagination.propTypes = {
  page: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  facetsFilters: PropTypes.object,
  numericFilters: PropTypes.object,
  numberOfPages: PropTypes.number.isRequired,
  query: PropTypes.string.isRequired,
  sortingOption: PropTypes.string,
  fetchAds: PropTypes.func,
};

const mapStateToProps = ({
  searchResults,
  selectedFacetsFilters,
  selectedNumericFilters,
  page,
  currentSearchQuery,
  sortingOption,
}) => ({
  query: currentSearchQuery,
  facetsFilters: selectedFacetsFilters,
  numericFilters: selectedNumericFilters,
  sortingOption,
  numberOfPages: searchResults.numberOfPages,
  page,
});

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setPage: (page) => dispatch(setPage(page)),
    fetchAds: (query, facetsFilters, adUnits) => dispatch(fetchAds(query, facetsFilters, adUnits)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchResultsPagination);
