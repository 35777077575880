import { PIXELS_PER_STEP } from '../constants/animations';
import { RESULTS_PER_PAGE } from '../constants/query';

import { getWindow } from '../utils/general';

const currentWindow = getWindow();

const requestAnimationFrame =
  currentWindow.requestAnimationFrame ||
  currentWindow.mozRequestAnimationFrame ||
  currentWindow.webkitRequestAnimationFrame ||
  currentWindow.msRequestAnimationFrame;

const cancelAnimationFrame = currentWindow.cancelAnimationFrame || currentWindow.mozCancelAnimationFrame;

let frameId = 0;

const scrollStep = () => {
  if (currentWindow.pageYOffset) {
    currentWindow.scroll(0, currentWindow.pageYOffset - PIXELS_PER_STEP * RESULTS_PER_PAGE);
    frameId = requestAnimationFrame(scrollStep);
  } else {
    cancelAnimationFrame(frameId);
  }
};

export const scrollToTop = () => {
  frameId = requestAnimationFrame(scrollStep);
};
