import React, { useState } from 'react';
import Autocomplete from 'react-autocomplete';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { last } from 'lodash/fp';
import { getFacetValueName } from '../../../utils/facets';
import { mapWithKey } from '../../../utils/general';
import { trackEvent } from '../../../utils/analytics';
import { selectFacetFilter, unselectFacetFilter, reSelectFacetFilter } from '../../../actions/selected-facets-filters';
import { levelCountFromMe } from '../../../utils/facets';

const TypeAhead = (props) => {
  const { list, filter, type, inputValue, onChangeFunc, className, placeHolder, wrapperClassName } = props;

  const [value, setValue] = useState(inputValue ? inputValue : '');

  const onSearchQueryChange = (event) => {
    let val = event.target.value;
    setValue(val);
    onChangeFunc(val);
  };

  const onSearchQuerySelected = (val) => {
    if (!val) {
      return;
    }
    const facetList = mapWithKey((numOfResults, key) => key, list);
    let facet;
    let level;

    for (var i = 0; i < facetList.length; i++) {
      if (last(facetList[i].split(' > ')) === val) {
        facet = facetList[i];
        level = facetList[i].split(' > ').length - 1;
      }
    }

    const navTo = true;
    if (filter && filter.toLowerCase().split(' > ')[level] === val.toLowerCase() && facet !== filter) {
      props.reSelectFacetFilter({
        facetToSelect: { [`${type}.lvl${level}`]: facet },
        facetToUnselect: `${type}.lvl${level + levelCountFromMe(val, filter)}`,
      });
      invokeAnalyticsCall(type, filter.split(' > ')[level + levelCountFromMe(val, filter)], !navTo);
      invokeAnalyticsCall(type, val, navTo);
      return;
    }

    props.selectFacetFilter({ [`${type}.lvl${level}`]: facet });
    setValue('');
    invokeAnalyticsCall(type, val, navTo);
  };

  const invokeAnalyticsCall = (type, label, navTo) => {
    const nav = navTo ? 'NAVTO' : 'NAVFROM';
    const formattedType = type.charAt(0).toUpperCase() + type.slice(1);

    trackEvent(`${formattedType}-Nav-Search-${label}-${nav}`);
  };

  const onItemFiltering = (item, value) => {
    if (value.length < 3) {
      return false;
    }
    return item.toLowerCase().indexOf(value.toLowerCase()) > -1;
  };

  const menuClass = {
    boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)',
    background: 'rgba(255, 255, 255)',
    padding: '0 0 0 0',
    fontSize: '14px',
    position: 'relative',
    overflow: 'auto',
    maxHeight: '50%',
    zIndex: 999999,
    left: 0,
    top: 0,
  };

  const formattedList = mapWithKey((numOfResults, key) => getFacetValueName(key), list);

  return (
    <Autocomplete
      items={formattedList}
      shouldItemRender={(item, value) => onItemFiltering(item, value)}
      wrapperProps={{ className: wrapperClassName }}
      inputProps={{ className: className, placeholder: placeHolder }}
      getItemValue={(item) => item}
      menuStyle={menuClass}
      autoHighlight={false}
      renderItem={(item, highlighted) => (
        <div
          key={item}
          class="facet-search__autocomplete-item"
          style={{
            backgroundColor: '#fff',
            cursor: 'pointer',
            padding: '8px 10px 8px 29px',
            fontSize: '14px',
            color: highlighted ? '#007EFF' : '#111111',
            border: '1px solid #111111',
            borderTop: 'none',
          }}
        >
          {item}
        </div>
      )}
      value={value}
      onChange={(event) => onSearchQueryChange(event)}
      onSelect={(val) => onSearchQuerySelected(val)}
    />
  );
};

TypeAhead.propTypes = {
  list: PropTypes.any.isRequired,
  filter: PropTypes.any.isRequired,
  type: PropTypes.string.isRequired,
  inputValue: PropTypes.string.isRequired,
  onChangeFunc: PropTypes.any.isRequired,
  className: PropTypes.string.isRequired,
  placeHolder: PropTypes.string.isRequired,
  wrapperClassName: PropTypes.string.isRequired,
  reSelectFacetFilter: PropTypes.func,
  selectFacetFilter: PropTypes.func,
};

export default connect(null, {
  selectFacetFilter,
  unselectFacetFilter,
  reSelectFacetFilter,
})(TypeAhead);
