import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

class PersonTitle extends React.Component {
  render() {
    const title = this.props.title;

    if (!title) {
      return null;
    }

    return <Container>{title}</Container>;
  }
}

const Container = styled.div`
  font-family: 'Lab Grotesque', sans-serif;
  line-height: 1.4;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  font-style: normal;

  font-size: 16px;
  color: #777;
  margin-top: 0px;
  width: 100%;
`;

PersonTitle.propTypes = {
  title: PropTypes.string,
};

export default PersonTitle;
