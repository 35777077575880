import { handleActions } from 'redux-actions';

import * as AT from '../constants/action-types';
import { SORTING_OPTIONS } from '../constants/sorting-options';

const initialState = SORTING_OPTIONS.BEST_MATCH;

const sortByReducer = handleActions(
  {
    [AT.SET_SORTING_OPTION]: (state, { payload }) => payload.sortingOption,

    [AT.SET_INITIAL_SEARCH_QUERY_FROM_URL_PARAMS]: (state, { payload }) => payload.sortingOption,
  },
  initialState
);

export default sortByReducer;
