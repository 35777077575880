import * as AT from '../constants/action-types';

import { trackEvent } from '../utils/analytics';

export const setPage = (page) => {
  trackEvent(`Pagination-${page}`);

  return {
    type: AT.SET_PAGE,
    payload: {
      page,
    },
  };
};

export const setPageWithoutTracking = (page) => {
  return {
    type: AT.SET_PAGE,
    payload: {
      page,
    },
  };
};
