import * as AT from '../constants/action-types';
import { SEARCH_QUERY_DEBOUNCE } from '../constants/debounce';

export const setSearchQuery = (query) => ({
  type: AT.SET_SEARCH_QUERY,
  payload: {
    query,
  },
});

export const submitSearchQuery = (query) => ({
  type: AT.SUBMIT_SEARCH_QUERY,
  payload: {
    query,
  },
});

export const newSearchQuery = () => ({
  meta: {
    debounce: SEARCH_QUERY_DEBOUNCE,
  },
  type: AT.NEW_SEARCH_QUERY,
  payload: {},
});
