import React, { useState } from 'react';
import PopperTooltip from './PopperTooltip';
import styled from 'styled-components';

const PremiumTag = (props) => {
  const [hover, setHover] = useState(false);

  return (
    <PremiumTagWrapper>
      <PopperTooltip
        controlShow
        showArrow
        showPopper={hover}
        target={
          <TagWrapper onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
            <TooltipTrigger>
              PREMIUM
              <i style={{ fontSize: '10px', color: 'ffffff', marginLeft: '6px' }} class="fas fa-info-circle"></i>
            </TooltipTrigger>
          </TagWrapper>
        }
        placement="bottom"
        distance={10}
        arrowClass="premium-arrow"
      >
        <PremiumTagTooltip>Premium includes enhanced forecast data.</PremiumTagTooltip>
      </PopperTooltip>
    </PremiumTagWrapper>
  );
};

export default PremiumTag;

export const PremiumTagWrapper = styled.div`
  .premium-arrow::after {
    background-color: white !important;
  }
`;

export const PremiumTagTooltip = styled.div`
  background-color: white;
  color: #777777;
  width: 185px;
  box-shadow: 0px 10px 24px 4px #1111111a;
  padding: 16px;
  border-radius: 4px;
  font-family: 'Lab Grotesque', sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 12px;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
`;

export const TagWrapper = styled.div`
  cursor: default;
  background-color: #007eff;
  padding: 4px 8px;
  margin-left: 15px;
  border-radius: 10px;
`;

export const TooltipTrigger = styled.div`
  display: flex;
  align-items: center;
  color: white;
  cursor: default;
  position: relative;
  font-family: 'Lab Grotesque', sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 10px;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
`;
