import React from 'react';
import { last, findIndex, slice, includes } from 'lodash/fp';

import config from '../config';
import { FACETS } from '../constants/facets';
import { omitByWithkey } from '../utils/general';

import BoldField from '../components/common/bold-field';

import { FILTERS } from '../constants/facets';
import { PUBLIC_AUDIENCE } from '../constants/config';

export const visibleFacetMap = () => {
  return FACETS.filter((f) => isVisible(f, config.AUDIENCE)).reduce(
    (map, f) => Object.assign(map, { [f.key]: f.filterField }),
    {}
  );
};

export const getFacetName = (facet) => {
  const facetFilterField = cleanFacetType(facet);
  const facetConfig = FACETS.find(({ filterField }) => filterField === facetFilterField);

  return facetConfig ? facetConfig.title : '';
};

/**
 * Which facets should be displayed
 * @param visibility [PUBLIC_AUDIENCE]
 */
export const getInitialFacetState = (visibility = PUBLIC_AUDIENCE) => {
  return FACETS.filter((f) => f.visibility.includes(visibility))
    .map((f) => f.initialState)
    .reduce((facets, is) => Object.assign(facets, is), {});
};

export const cleanFacetType = (type) => (type ? type.split('.lvl')[0] : undefined);

export const isMultiselectFacet = (type) => {
  const facetConfig = FACETS.find((f) => f.filterField === cleanFacetType(type));
  return 'multiSelect' in facetConfig && facetConfig.multiSelect;
};

export const getFacetTypeFromSelectionFilterPayload = (payload) => {
  return typeof payload === 'string' ? cleanFacetType(payload) : cleanFacetType(Object.keys(payload)[0]);
};

export const isSelectionPayloadMultiselect = (payload) => {
  return isMultiselectFacet(getFacetTypeFromSelectionFilterPayload(payload));
};

export const getFacetConfig = (type) => FACETS.find((f) => f.filterField === cleanFacetType(type));

export const isVisible = (facetConfig, visibility) => facetConfig.visibility.includes(visibility);

export const filterVisibleFacetState = (facets) => {
  return Object.entries(facets)
    .filter(([k, v]) => isVisible(getFacetConfig(k), config.AUDIENCE))
    .reduce((filtered, [k, v]) => Object.assign(filtered, { [k]: v }), {});
};

export const levelCountFromMe = (me, facetList) => {
  const facets = facetList.split(' > ');
  const meIndex = findIndex((facet) => facet === me, facets);

  return facets.length - meIndex - 1;
};

export const getFacetValueName = (facetValue) => {
  const facetValueString = facetValue.split(' > ');

  return last(facetValueString);
};

export const getDisplayString = (filter, narrowest) => {
  const currentFacetIndex = findIndex((str) => str === filter, narrowest);

  if (currentFacetIndex < 0) {
    return narrowest.join('; ');
  }

  const firstSlice = slice(0, currentFacetIndex, narrowest);
  const secondSlice = slice(currentFacetIndex + 1, narrowest.length, narrowest);

  return (
    <span>
      {firstSlice.join('; ')}
      {firstSlice.length ? '; ' : ''}
      <BoldField>{narrowest[currentFacetIndex]}</BoldField>
      {secondSlice.length ? '; ' : ''}
      {secondSlice.join('; ')}
    </span>
  );
};

export const filterFacetsByType = (facets, type) =>
  omitByWithkey((facetValue, facetKey) => {
    const facetTypeString = cleanFacetType(facetKey);

    return facetTypeString && facetTypeString !== type;
  }, facets);

export const getByLevel = (facets, level) =>
  omitByWithkey((facetValue, facetKey) => {
    const facetString = facetKey.split('.lvl');

    return facetString[1] && parseInt(facetString[1], 10) !== level;
  }, facets);

export const getOnlyFirstLevel = (facets) =>
  omitByWithkey((facetValue, facetKey) => {
    const facetString = facetKey.split('.lvl');

    return (facetString[1] && facetString[1] > 0) || !includes(facetString[0], FILTERS);
  }, facets);
