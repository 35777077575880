import React from 'react';
import PropTypes from 'prop-types';
import PillTooltip from '../molecules/PillTooltip';
import DrilldownList from '../molecules/DrilldownList';
import { connect } from 'react-redux';
import { getFacetsByLevelAndType } from '../../../selectors/facets';
import { DATE_FILTERS } from '../../../constants/facets';
import moment from 'moment/moment';
import SearchTypeAhead from '../molecules/SearchTypeAhead';

const Facet = (props) => {
  const { filter, type, active, filterOnClick, facetsLvl0, facetsLvl1, facetsLvl2, facetsSelected, toggleFilter } =
    props;

  // Start digesting whatever the redux selectors return into
  // something we can sort into a hierarchical taxonomy.
  const getFacets = (level, facets) => {
    if (!Object.keys(facets).length) return [];
    // We save the key here because we need to supply it
    // when selecting a filter.
    // ex: { geographies.lvl2: 'Region > Country' } (key is geographies.lvl2)
    const facetKey = Object.keys(facets)[0];
    const facetObj = JSON.parse(JSON.stringify(facets[facetKey]));
    // We make an exception for the only numeric filter published date.
    if (facetKey === 'published_date') {
      Object.keys(facetObj).forEach((key) => {
        const val = facetObj[key];
        delete Object.assign(facetObj, {
          [DATE_FILTERS[key].shownText]: {
            value: val,
            facet: {
              [facetKey]: {
                displayString: DATE_FILTERS[key].shownText,
                ...(typeof facetObj[key] !== 'undefined' && {
                  from: parseInt(
                    moment()
                      .subtract(DATE_FILTERS[key].daysToSubtract ? DATE_FILTERS[key].daysToSubtract : 3650, 'days')
                      .unix(),
                    10
                  ),
                  to: parseInt(moment().unix(), 10),
                }),
              },
            },
          },
        })[key];
      });
    } else {
      // Value is the number of results.
      // Facet is the object we need to pass back into the
      // redux action when selecting the filter.
      Object.keys(facetObj).forEach((key) => {
        const val = facetObj[key];
        facetObj[key] = {
          value: val,
          facet:
            facetKey === 'type'
              ? key
              : {
                  [facetKey]: key,
                },
        };
      });
    }
    // Content type facet will have multiple levels despite
    // only being one level. Return an empty array when
    // encountered.
    if (!Object.keys(facetObj).filter((key) => key.includes(' > ') || level === 0).length) return [];
    return Object.entries(facetObj);
  };

  const lvl0facets = getFacets(0, facetsLvl0);
  const lvl1facets = getFacets(1, facetsLvl1);
  const lvl2facets = getFacets(2, facetsLvl2);

  // Starting converting the levels to taxonomy.
  // Nothing fancy here, we know there are up to 2 levels max.
  const getTaxonomy = (lvl0, lvl1, lvl2) => {
    let taxonomy = {};
    lvl0.forEach((f) => {
      taxonomy[f[0]] = {
        counter: f[1].value,
        value: f[1].facet,
        children: {},
      };
    });
    lvl1.forEach((f) => {
      var path = f[0].split(' > ');
      if (!taxonomy[path[0]]) return;
      taxonomy[path[0]].children[path[1]] = {
        counter: f[1].value,
        value: f[1].facet,
        children: {},
      };
    });
    lvl2.forEach((f) => {
      var path = f[0].split(' > ');
      if (!taxonomy[path[0]] || !taxonomy[path[0]].children[path[1]]) return;
      taxonomy[path[0]].children[path[1]].children[path[2]] = {
        counter: f[1].value,
        value: f[1].facet,
      };
    });
    return taxonomy;
  };

  const taxonomy = getTaxonomy(lvl0facets, lvl1facets, lvl2facets);

  let showSearch;
  switch (type) {
    case 'demographics':
    case 'geographies':
    case 'topics':
    case 'industries':
      showSearch = true;
      break;
    default:
      showSearch = false;
      break;
  }

  if (!Object.keys(taxonomy).length) return null;

  return (
    <PillTooltip
      name={filter.label}
      callback={() => filterOnClick(filter.type)}
      active={active}
      counter={filter.isMultiSelect ? facetsSelected.length : facetsSelected.length ? 1 : 0}
      tooltipProps={{
        skid: filter.skid,
      }}
    >
      {showSearch && (
        <SearchTypeAhead type={type} facetsLvl0={facetsLvl0} facetsLvl1={facetsLvl1} facetsLvl2={facetsLvl2} />
      )}
      <DrilldownList
        callback={(key) => {
          const toggle =
            filter.type === 'published_date' &&
            key['published_date'].displayString === DATE_FILTERS[DATE_FILTERS.length - 1].shownText
              ? false
              : !facetsSelected.includes(key);
          toggleFilter(toggle, key);
          // Close the tooltip when we are finished with our selection.
          if (!filter.isMultiSelect) filterOnClick(filter.type);
        }}
        defaultSelected={facetsSelected}
        taxonomy={taxonomy}
        hideCounter={filter.type === 'published_date'}
        {...filter}
      />
    </PillTooltip>
  );
};

Facet.propTypes = {
  filter: PropTypes.any.isRequired,
  type: PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired,
  filterOnClick: PropTypes.func.isRequired,
  facetsLvl0: PropTypes.object,
  facetsLvl1: PropTypes.object,
  facetsLvl2: PropTypes.object,
  facetsSelected: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  toggleFilter: PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => ({
  facetsLvl0: getFacetsByLevelAndType(0, ownProps.filter.type)(state),
  facetsLvl1: getFacetsByLevelAndType(1, ownProps.filter.type)(state),
  facetsLvl2: getFacetsByLevelAndType(2, ownProps.filter.type)(state),
});

export default connect(mapStateToProps)(Facet);
