import Article from '../components/search-results/result/result-types/article';
import Brief from '../components/search-results/result/result-types/brief';
import Chart from '../components/search-results/result/result-types/chart';
import Interview from '../components/search-results/result/result-types/interview';
import Numbers from '../components/search-results/result/result-types/numbers';
import KPI from '../components/search-results/result/result-types/industrykpi';
import Report from '../components/search-results/result/result-types/report';
import Webinar from '../components/search-results/result/result-types/webinar';
import Audio from '../components/search-results/result/result-types/audio';
import Video from '../components/search-results/result/result-types/video';
import Podcast from '../components/search-results/result/result-types/podcast';
import Annotation from '../components/search-results/result/result-types/annotation';
import Research from '../components/search-results/result/result-types/content-blog';
import Source from '../components/search-results/result/result-types/annotation-source';
import Ad from '../components/search-results/result/result-types/ad';
import TextAd from '../components/search-results/result/result-types/text-ad';

export const RESULT_TYPES = {
  Ad,
  Article,
  Brief,
  Chart,
  Interview,
  KPI,
  Forecasts: Numbers,
  Numbers,
  Podcast,
  Report,
  TextAd,
  Webinar,
  Audio,
  Video,
  Annotation,
  Research,
  Source,
};

export const TITLE_IMAGE_POSITION = {
  Article: '0 -39px',
  Chart: '0 0px',
  Numbers: '0 0px',
  KPI: '0 0px',
  Brief: '0 -273px',
  Interview: '0 -117px',
  Report: '0 -78px',
  Audio: '0 -352px',
  Podcast: '0 -352px',
  Video: '0 -156px',
  Webinar: '0 -156px',
  Annotation: '0 -39px',
  Research: '0 -39px',
  Source: '0 -39px',
};
