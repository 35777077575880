import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { FlexContainer } from '@insiderintelligence/componentlibrary';
import FilterButton from '../reusable-components/molecules/FilterButton';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { unselectFacetFilter, selectFacetFilter, clearAllFilters } from '../../actions/selected-facets-filters';
import { unselectNumericFilter, selectNumericFilter } from '../../actions/selected-numeric-filters';
import { DATE_FILTERS, FACETS, FACET_CASE_EXCEPTIONS } from '../../constants/facets';
import Facet from '../reusable-components/organisms/Facet';
import { PUBLIC_AUDIENCE } from '../../constants/config';
import { trackEvent } from '../../utils/analytics';
import { FILTERS } from '../../constants/facets';
import { AppContext } from '../AppContext';

const TopBarFilterTabs = (props) => {
  const {
    selectFacetFilter,
    selectNumericFilter,
    selectedFacetsFilters,
    selectedNumericFilters,
    clearAllFilters,
    unselectFacetFilter,
    unselectNumericFilter,
  } = props;
  const { scrolled, isMobile } = useContext(AppContext);
  const [activeFilter, setActiveFilter] = useState('');
  const selectedFacetKeys = Object.keys(selectedFacetsFilters);
  const selectedNumericFacetKeys = Object.keys(selectedNumericFilters);

  // Scroll functionality
  if (typeof window !== 'undefined') {
    const ele = document ? document.getElementById('filterScrollable') : null;
    if (ele) {
      ele.scrollTop = 0;
      ele.scrollLeft = 0;
    }

    let pos = { top: 0, left: 0, x: 0, y: 0 };

    const mouseDownHandler = (e) => {
      if (typeof window !== 'undefined') {
        // Change the cursor and prevent user from selecting the text
        ele.style.userSelect = 'none';
        pos = {
          // The current scroll
          left: ele.scrollLeft,
          top: ele.scrollTop,
          // Get the current mouse position
          x: e.clientX,
          y: e.clientY,
        };

        document.addEventListener('mousemove', mouseMoveHandler);
        document.addEventListener('mouseup', mouseUpHandler);
      }
    };

    const mouseMoveHandler = (e) => {
      // How far the mouse has been moved
      const dx = e.clientX - pos.x;
      const dy = e.clientY - pos.y;

      // Scroll the element
      ele.scrollTop = pos.top - dy;
      ele.scrollLeft = pos.left - dx;
    };

    const mouseUpHandler = function () {
      if (typeof window !== 'undefined') {
        document.removeEventListener('mousemove', mouseMoveHandler);
        document.removeEventListener('mouseup', mouseUpHandler);
        ele.style.removeProperty('user-select');
      }
    };

    if (ele) {
      ele.addEventListener('mousedown', mouseDownHandler);
    }
  }

  // Get the display name for a facet
  const getFacet = (str) => {
    //TODO: we should look up the taxonomy in order to get the matching casing for the facet
    return String(str)
      .toLowerCase()
      .replace('kpi', 'KPI') // Capitalize KPI
      .replace(/\([a-zA-Z]+\)/g, (x) => x.toUpperCase().replace('(IOT)', '(IoT)')) // Capitalize all characters between parentheses, except for 'IOT'
      .replace(
        new RegExp(`(\\b|-|\\d)${FACET_CASE_EXCEPTIONS.reduce((agg, x) => agg + `(?!${x})`, '')}[a-zA-Z]`, 'g'),
        (x) => x.toUpperCase()
      ); // Capitalize the first letter of each word
  };

  const getFacetDisplayName = (str) => {
    return getFacet(str).split(' > ').pop(); // Return the lowest level of the hierarchy
  };

  // Initialize Filter buttons and all the Clear All
  let clearFiltersButton = <FilterButton name={'Clear All'} callback={() => filterRemoveAllOnClick()} ghost={true} />;
  let filterButtons = [];

  // Add in Selected Filters
  selectedFacetKeys.map((key) => {
    // Add in Types which are stored as array
    if (key === 'type') {
      selectedFacetsFilters[key].map((type) => {
        filterButtons.push(
          <FilterButton
            name={getFacetDisplayName(type)}
            callback={() => filterRemoveOnClick(key, type, unselectFacetFilter, { type: type })}
          />
        );
        return filterButtons;
      });
    } else {
      filterButtons.push(
        <FilterButton
          name={getFacetDisplayName(selectedFacetsFilters[key])}
          callback={() => filterRemoveOnClick(key, selectedFacetsFilters[key], unselectFacetFilter, key)}
        />
      );
    }
    return filterButtons;
  });

  // Add in Numeric Filters
  selectedNumericFacetKeys.map((key) => {
    filterButtons.push(
      <FilterButton
        name={selectedNumericFilters[key].displayString}
        callback={() => filterRemoveOnClick(key, selectedNumericFilters[key], unselectNumericFilter, key)}
      />
    );
    return filterButtons;
  });

  // Handler for pill toggle
  const filterOnClick = (category) => {
    if (category === activeFilter) {
      setActiveFilter('');
      trackEvent(`Nav-Toggle-${category}-Closed`);
    } else {
      setActiveFilter(category);
      trackEvent(`Nav-Toggle-${category}-Open`);
    }
  };

  // Unselecting All
  const filterRemoveAllOnClick = () => {
    clearAllFilters();
    trackEvent(`Clear-Refinement-ClearAll`);
  };

  // Unselecting Handler
  const filterRemoveOnClick = (analyticsKey, analyticsValue, onClickHandlerFunc, onClickHandlerParams) => {
    filterRemoveAnalytics(analyticsKey, analyticsValue);
    onClickHandlerFunc(onClickHandlerParams);
  };

  // Unselecting Analytics
  const filterRemoveAnalytics = (facetFilterKey, facetFilterValue) => {
    const label =
      facetFilterKey === FILTERS.TYPE
        ? `${facetFilterValue}`
        : facetFilterKey === FILTERS.PUBLISHED_DATE
        ? `${facetFilterKey.split('.lvl')[0]}: ${facetFilterValue.displayString}`
        : `${facetFilterKey.split('.lvl')[0]}: ${facetFilterValue}`;

    trackEvent(`Clear-Refinement-${label}`);
  };

  // We want the tooltips positioned at either edge of the pill
  // hasSelections will utilize additionalSkid in case of
  // a pill having an extended width due to having selections.
  // forcePositive is utilized when the last pill having a
  // negative skid needs to be positive because in some cases
  // it becomes the first and only pill rendered.
  const getFacetSkidByType = (type, hasSelections, forcePositiveSkid, additionalSkid = -13) => {
    let skid = hasSelections ? additionalSkid : 0;
    switch (type) {
      case 'demographics':
        skid += 78;
        break;
      case 'type':
        skid += 83;
        break;
      case 'geographies':
        skid += 90;
        break;
      case 'topics':
        skid += 113;
        break;
      case 'industries':
        skid += 102;
        break;
      case 'published_date':
        skid *= forcePositiveSkid ? 1 : -1;
        skid -= forcePositiveSkid ? -69 : 69;
        break;
      default:
        break;
    }
    return skid;
  };

  // Digest and translate what redux returns (object) into a
  // simple array of strings.
  // Each filter is it's own special snowflake.
  const getSelectedFacets = (filterField) => {
    let selected = [];
    if (filterField === 'published_date') {
      selected = selectedNumericFilters['published_date']
        ? [selectedNumericFilters['published_date'].displayString]
        : [DATE_FILTERS[DATE_FILTERS.length - 1].shownText];
      return selected;
    } else if (filterField === 'type') selected = selectedFacetsFilters['type'] ? selectedFacetsFilters['type'] : [];
    else
      selected = Object.entries(selectedFacetsFilters).filter(([key, val]) => key.includes(filterField)).length
        ? Object.entries(selectedFacetsFilters)
            .filter(([key, val]) => key.includes(filterField))[0][1]
            .split(' > ')
        : [];
    return selected.map((x) => getFacet(x));
  };

  // Facet Toggle

  const toggleAnalyticsCall = (label, navTo) => {
    const nav = navTo ? 'NAVTO' : 'NAVFROM';

    trackEvent(`Hierarchical-Nav-${label}-${nav}`);
  };

  const facetList = FACETS.filter((facet) => facet.visibility.includes(PUBLIC_AUDIENCE)).map((facet, index) => {
    const selected = getSelectedFacets(facet.filterField);

    const filter = {
      label: facet.title,
      type: facet.filterField,
      isMultiSelect: facet.multiSelect,
      rootSelectable: facet.rootSelectable,
      visibility: facet.visibility,
      skid: getFacetSkidByType(facet.filterField, selected.length > 0, index === 0),
    };

    const toggleFilterOnClick = (toggle, filter) => {
      if (facet.filterField === 'published_date') {
        if (toggle) selectNumericFilter(filter);
        else unselectNumericFilter(facet.filterField);
      } else {
        if (facet.multiSelect) {
          if (toggle) selectFacetFilter({ [facet.filterField]: filter.toLocaleLowerCase() });
          else unselectFacetFilter({ [facet.filterField]: filter.toLocaleLowerCase() });
        } else {
          if (toggle) selectFacetFilter(filter);
          else unselectFacetFilter(facet.filterField);
        }
        if (facet.filterField !== 'type') {
          const key = Object.keys(filter)[0];
          toggleAnalyticsCall(filter[key], toggle);
        } else {
          const checkStatus = toggle ? 'Checked' : 'Unchecked';
          trackEvent(`CheckBox-${filter}-${checkStatus}`);
        }
      }
    };

    return (
      <Facet
        key={filter.skid}
        filter={filter}
        type={facet.filterField}
        active={facet.filterField === activeFilter}
        facetsSelected={selected}
        toggleFilter={toggleFilterOnClick}
        filterOnClick={filterOnClick}
      />
    );
  });

  return (
    <div>
      <Container flexDirection="row" justifyContent="start" spaceBetween={8} isMobile={isMobile}>
        {facetList}
      </Container>
      {filterButtons.length > 0 && (
        <SelectedFiltersWrapper scrolled={scrolled}>
          {clearFiltersButton}
          <SelectedFiltersWrapperScroll id="filterScrollable">{filterButtons}</SelectedFiltersWrapperScroll>
        </SelectedFiltersWrapper>
      )}
    </div>
  );
};

const Container = styled(FlexContainer)`
  font-family: 'Lab Grotesque', sans-serif;
  line-height: 1.4;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 700;
  font-style: normal;
  font-size: 16px;
  overflow-x: auto;
  background-image: linear-gradient(to right, rgb(245, 245, 245), rgb(245, 245, 245)),
    linear-gradient(to right, rgb(245, 245, 245), rgb(245, 245, 245)),
    linear-gradient(to right, rgba(0, 0, 0, 0.1), rgba(255, 255, 255, 0)),
    linear-gradient(to left, rgba(0, 0, 0, 0.1), rgba(255, 255, 255, 0));
  background-position: left center, right center, left center, right center;
  background-repeat: no-repeat;
  background-color: rgb(245, 245, 245);
  background-size: 20px 100%, 20px 100%, 10px 100%, 10px 100%;
  background-attachment: local, local, scroll, scroll;
  flex-wrap: ${({ isMobile }) => (isMobile ? 'nowrap' : 'wrap')};

  @media (max-width: 767px) {
    padding: 0px 30px;
    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
`;

const SelectedFiltersWrapper = styled.div`
  display: flex;
  flex-direction: row;
  white-space: nowrap;
  align-items: center;
  gap: 16px;
  margin-top: 16px;
  margin-bottom: 34px;

  ${({ scrolled }) =>
    scrolled &&
    `
    margin-bottom: 16px;
  `}

  @media (max-width: 767px) {
    padding: 0px 0px 0px 30px;
  }
`;

const SelectedFiltersWrapperScroll = styled.div`
  display: flex;
  flex-direction: row;
  overflow-y: auto;
  gap: 16px;
  overflow: auto;

  ::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: 767px) {
    padding: 0px 30px 0px 0px;
    overflow-y: scroll; /* has to be scroll, not auto */
    -webkit-overflow-scrolling: touch;
  }
`;

TopBarFilterTabs.propTypes = {
  selectedFacetsFilters: PropTypes.object.isRequired,
  selectedNumericFilters: PropTypes.object.isRequired,
  unselectFacetFilter: PropTypes.func.isRequired,
  unselectNumericFilter: PropTypes.func.isRequired,
  selectFacetFilter: PropTypes.func.isRequired,
  selectNumericFilter: PropTypes.func.isRequired,
  clearAllFilters: PropTypes.func,
};

const mapStateToProps = ({ selectedFacetsFilters, selectedNumericFilters }) => ({
  selectedFacetsFilters,
  selectedNumericFilters,
});

export default connect(mapStateToProps, {
  selectFacetFilter,
  selectNumericFilter,
  unselectFacetFilter,
  unselectNumericFilter,
  clearAllFilters,
})(TopBarFilterTabs);
