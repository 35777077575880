import { handleActions } from 'redux-actions';
import * as AT from '../constants/action-types';

const initialState = {
  results: [],
};

const relatedQueriesReducer = handleActions(
  {
    [AT.RELATED_QUERIES.SUCCESS]: (state, { payload }) => {
      const newState = {
        results: payload.relatedQueries,
      };

      return newState;
    },
    [AT.RELATED_QUERIES.FAILURE]: (state, { payload }) => {
      const newState = {
        results: [],
      };

      return newState;
    },
  },
  initialState
);

export default relatedQueriesReducer;
