import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Highlight } from '../../../../components/common/highlight';

const AnnotationQuote = ({ result, searchQuery }) => {
  if (!result.blurb) {
    return null;
  }

  return (
    <Container>
      <Highlight text={result.blurb} textToHighlight={searchQuery} />
    </Container>
  );
};

AnnotationQuote.propTypes = {
  result: PropTypes.object.isRequired,
  searchQuery: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  searchQuery: state.currentSearchQuery,
});

const Container = styled.p`
  font-family: 'Lab Grotesque', sans-serif;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  font-style: normal;
  color: gray;
  font-size: 16px;
  border-left: 3px solid;
  padding: 0 1em;
  em {
    font-weight: 700;
    font-style: normal;
    color: #111;
  }
`;

export default connect(mapStateToProps)(AnnotationQuote);
