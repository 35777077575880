import React, { useState, useEffect } from 'react';
// This is a simple library we use to tell us if the code is running in Node or in a browser.
import isNode from 'detect-node';
import PropTypes from 'prop-types';

export const IdleUntilUrgent = ({ children, htmlElement }) => {
  const [callbackId, setCallbackId] = useState(null);
  const [renderChild, setRenderChild] = useState(false);

  useEffect(() => {
    if (!isNode) {
      if (typeof window.requestIdleCallback !== 'undefined') {
        // https://caniuse.com/#search=requestIdleCallback
        setCallbackId(
          window.requestIdleCallback(() => {
            setRenderChild(true);
          })
        );
      } else {
        setTimeout(() => {
          setRenderChild(true);
        });
      }
    }
  }, []);

  if (!isNode && !renderChild) {
    // eslint-disable-next-line react/no-danger-with-children
    return React.createElement(
      htmlElement,
      {
        dangerouslySetInnerHTML: { __html: '' },
        suppressHydrationWarning: true,
        onClick: () => setRenderChild(true),
      },
      null
    );
  }

  // Cancel the already scheduled render, if any
  if (!isNode && callbackId) {
    window.cancelIdleCallback(callbackId);
  }

  return children;
};

IdleUntilUrgent.propTypes = {
  children: PropTypes.any,
  htmlElement: PropTypes.any,
};
