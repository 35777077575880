import React from 'react';
import PropTypes from 'prop-types';
import Type from '../sub-components/type';
import Title from '../sub-components/title';
import PubDate from '../sub-components/pub-date';
import ResultContainer from './result-container';
import Summary from '../sub-components/summary';
import Source from '../sub-components/source';

class IndustryKPI extends React.Component {
  render() {
    const { result } = this.props;

    const sources =
      result.sources && result.sources.length > 0
        ? result.sources.map((item, i) => {
            return item.replace('Insider Intelligence', 'EMARKETER');
          })
        : [];

    return (
      <ResultContainer>
        <Title result={result} title={result.title} type={result.type} />
        <Summary result={result} attributeName="deck" />
        {/* Need List of Breakdowns with links here - not currently included in the payload*/}
        {/* Can use split link list from numbers example when we get that data flowing */}
        <Type type={result.type} />
        &nbsp;|&nbsp;
        <PubDate date={new Date(result.published_date).toUTCString()} />
        <Source source={sources} />
      </ResultContainer>
    );
  }
}

IndustryKPI.propTypes = {
  result: PropTypes.object.isRequired,
};

export default IndustryKPI;
