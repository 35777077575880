import { handleActions } from 'redux-actions';

import * as AT from '../constants/action-types';

const initialState = {
  app: '',
  location: '',
};

const sourceReducer = handleActions(
  {
    [AT.SET_SOURCE]: (state, { payload }) => ({
      app: payload.app,
      location: payload.location,
    }),
  },
  initialState
);

export default sourceReducer;
