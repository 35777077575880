import config from '../config';
import { defaultNumericFilters } from '../constants/facets';
import { includes, isEmpty } from 'lodash/fp';
import {
  SEARCH,
  SET_PAGE,
  SET_SEARCH_QUERY,
  SET_INITIAL_SEARCH_QUERY_FROM_URL_PARAMS,
} from '../constants/action-types';
import { ACTION_TO_INITIATE_SEARCH } from '../constants/search';
import { setPageWithoutTracking } from '../actions/page';
import { getSearchProvider } from '../utils/search';
import { insertAds } from '../utils/search-results';
import { newSearchQuery } from '../actions/current-search-query';
import { RESULTS_PER_PAGE } from '../constants/query';
import {
  AD_SLOT_TYPE__MIDDLE_FLUID1,
  AD_SLOT_TYPE__MIDDLE_FLUID2,
  refreshGamSlots,
} from '../../node_modules/@insiderintelligence/googleadmanager/dist/index';

const searchMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    if (!includes(action.type, ACTION_TO_INITIATE_SEARCH)) {
      return;
    }

    if (action.type === SET_SEARCH_QUERY) {
      return; // ignore set events in middleware for standard search
    }

    // For server side rendering to not make a search
    if (typeof window !== 'object') {
      return;
    }

    if (action.type !== SET_PAGE && action.type !== SET_INITIAL_SEARCH_QUERY_FROM_URL_PARAMS) {
      dispatch(setPageWithoutTracking(1));
      return;
    }

    if (action.type !== SET_INITIAL_SEARCH_QUERY_FROM_URL_PARAMS) {
      dispatch(newSearchQuery());
    }

    const state = getState();
    const query = state.currentSearchQuery;
    const facetsFilters = state.selectedFacetsFilters;
    const numericFilters = isEmpty(state.selectedNumericFilters)
      ? defaultNumericFilters()
      : state.selectedNumericFilters;
    const sortingOption = state.sortingOption;
    const page = state.page;

    return getSearchProvider().then((searchProvider) => {
      return searchProvider
        .searchWithTemplate({
          query,
          facetsFilters,
          numericFilters,
          sortingOption,
          resultsPerPage: RESULTS_PER_PAGE,
          page,
        })
        .then((response) => {
          document.title = `Search Results for ${query} | EMARKETER`;
          if (response.nbPages < page) {
            dispatch(setPageWithoutTracking(response.nbPages));
            return;
          } else {
            if (config.ADS_ENABLED && page <= 1) {
              insertAds(response.hits);

              //#region refresh ads inside results grid
              var slotsToRefresh = [AD_SLOT_TYPE__MIDDLE_FLUID1, AD_SLOT_TYPE__MIDDLE_FLUID2];
              refreshGamSlots(slotsToRefresh, 0, config.CURRENT_ENVIRONMENT);
              //#endregion refresh ads inside results grid
            }

            if (!response.indexVersion) {
              searchProvider.getIndexVersion().then((indexVersion) => {
                response.indexVersion = indexVersion;
                dispatch({
                  type: SEARCH.SUCCESS,
                  payload: response,
                });
              });
            } else {
              dispatch({
                type: SEARCH.SUCCESS,
                payload: response,
              });
            }
          }
        })
        .catch((error) => {
          dispatch({
            type: SEARCH.FAILURE,
            payload: error,
          });
        });
    });
  };

export default searchMiddleware;
