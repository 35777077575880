import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Highlight } from '../../../../components/common/highlight';
import User from '../../../../components/search-results/result/sub-components/user';

const AnnotationBody = ({ result, searchQuery }) => {
  if (!result.text) {
    return null;
  }

  return (
    <Container>
      <Highlight text={result.text} textToHighlight={searchQuery} />
      <UserContainer>
        <User user={result.user_name} />
      </UserContainer>
    </Container>
  );
};

const UserContainer = styled.div`
  font-family: 'Lab Grotesque', sans-serif;
  font-size: 80%;
  width: 100%;
  text-align: right;
  font-style: italic;
  color: #007eff;
`;

const Container = styled.div`
  font-family: 'Lab Grotesque', sans-serif;
  font-size: 80%;
  margin-top: 0px;
  width: 100%;
  text-align: right;
  font-style: italic;
  padding: 0.5em;
  color: #007eff;
`;

AnnotationBody.propTypes = {
  result: PropTypes.object.isRequired,
  searchQuery: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  searchQuery: state.currentSearchQuery,
});

export default connect(mapStateToProps)(AnnotationBody);
