import { Helmet } from 'react-helmet';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import config from '../config';
import { buildSearchQueryString } from '../utils/query';

class Head extends React.Component {
  render() {
    const { query, facetsFilters, numericFilters, sortingOption, numberOfPages, page } = this.props;
    const prevLink = numberOfPages >= page && page > 1;
    const nextLink = numberOfPages > page;
    return (
      <Helmet>
        <meta property="og:image" content={config.COMMON_DEFAULT_SOCIAL_THUMBNAIL_URL} />
        <meta property="og:image:type" content="image/png" />
        <meta property="og:image:width" content="200" />
        <meta property="og:image:height" content="200" />
        {prevLink && (
          <link
            rel="prev"
            href={`${config.SEO_BASE_URL}/?${buildSearchQueryString({
              query,
              facetsFilters,
              numericFilters,
              sortingOption,
              page: page - 1,
            })}`}
          />
        )}
        {nextLink && (
          <link
            rel="next"
            href={`${config.SEO_BASE_URL}/?${buildSearchQueryString({
              query,
              facetsFilters,
              numericFilters,
              sortingOption,
              page: page + 1,
            })}`}
          />
        )}
        <link rel="preload stylesheet prefetch" href={config.COMMON_FONTS_CSS_URL} as="style" />
      </Helmet>
    );
  }
}

Head.propTypes = {
  query: PropTypes.string.isRequired,
  facetsFilters: PropTypes.object,
  numericFilters: PropTypes.object,
  numberOfPages: PropTypes.number.isRequired,
  sortingOption: PropTypes.string,
  page: PropTypes.number.isRequired,
};

const mapStateToProps = ({
  searchResults,
  selectedFacetsFilters,
  selectedNumericFilters,
  page,
  currentSearchQuery,
  sortingOption,
}) => ({
  query: currentSearchQuery,
  facetsFilters: selectedFacetsFilters,
  numericFilters: selectedNumericFilters,
  sortingOption,
  numberOfPages: searchResults.numberOfPages,
  page,
});

export default connect(mapStateToProps, null)(Head);
