import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import config from '../../config';

class RelatedQueries extends React.Component {
  render() {
    if (!this.props.results || this.props.results.length === 0) return null;

    const relatedQueries = this.props.results.map((result) => {
      let searchUrl = encodeURI(`${config.SEARCH_BASE_URL}?query=${result.query}`);
      return (
        <RelatedQuery key={result.query} href={searchUrl} data-analytics={`RelatedSearches-Link-${result.query}`}>
          {result.query}
        </RelatedQuery>
      );
    });

    return (
      <React.Fragment>
        <RelatedQueryTitle>Others also searched for</RelatedQueryTitle>
        <RelatedQueriesWrapper>{relatedQueries}</RelatedQueriesWrapper>
      </React.Fragment>
    );
  }
}

RelatedQueries.propTypes = {
  results: PropTypes.array.isRequired,
};

const RelatedQueryTitle = styled.span`
  font-family: 'Lab Grotesque', sans-serif;
  line-height: 1.1;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-weight: 900;
  font-size: 20px;
  text-align: center;
  margin-top: 10px;
`;

const RelatedQueriesWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-flow: wrap;
  padding: 20px 10px 40px;
`;

const RelatedQuery = styled.a`
  font-family: 'Lab Grotesque', sans-serif;
  line-height: 1.1;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-weight: 400px;
  font-size: 14px;

  padding: 20px;
  border-radius: 25px;
  background-color: #d8d8d8;
  color: #111;
  padding: 10px 15px;
  margin: 0 10px 10px;

  &:hover {
    background-color: #bebebe;
  }
`;

const mapStateToProps = ({ relatedQueries }) => ({
  results: relatedQueries.results,
});

export default connect(mapStateToProps)(RelatedQueries);
