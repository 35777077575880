import { handleActions } from 'redux-actions';
import { unset, without, find } from 'lodash/fp';

import * as AT from '../constants/action-types';

import { FILTERS } from '../constants/facets';
import { isMultiselectFacet, getFacetTypeFromSelectionFilterPayload } from '../utils/facets';

const initialState = {};

const selectFacet = (state, { payload }) => {
  const facetType = getFacetTypeFromSelectionFilterPayload(payload.selectedFacetFilter);

  if (isMultiselectFacet(facetType)) {
    return state[facetType]
      ? {
          ...state,
          [facetType]: [...state[facetType], payload.selectedFacetFilter[facetType]],
        }
      : {
          ...state,
          [facetType]: [payload.selectedFacetFilter[facetType]],
        };
  } else {
    // Single-select facet
    const keyToRemove = find((key) => key.includes(facetType), Object.keys(state));

    return {
      ...unset(keyToRemove, state),
      ...payload.selectedFacetFilter,
    };
  }
};

const selectMultipleFacetFilters = (state, { payload }) => {
  if (payload.selectedFacetFilters.type) {
    return {
      ...state,
      type: payload.selectedFacetFilters.type,
    };
  }
};

const unselectFacetFiltersOfType = (state, { payload }) => {
  if (payload === FILTERS.TYPE) {
    return {
      ...unset(FILTERS.TYPE, state),
    };
  }
};

const unSelectFacet = (state, { payload }) => {
  const facetType = getFacetTypeFromSelectionFilterPayload(payload.facetFilterToRemove);

  return payload.facetFilterToRemove[facetType]
    ? isMultiselectFacet(facetType) && state[facetType].length > 1
      ? {
          ...state,
          [facetType]: without([payload.facetFilterToRemove[facetType]], state[facetType]),
        }
      : unset(facetType, state)
    : unset(payload.facetFilterToRemove, state);
};

const selectedFacetsFiltersReducer = handleActions(
  {
    [AT.SELECT_FACET_FILTER]: selectFacet,

    [AT.SELECT_MULTIPLE_FACET_FILTERS]: selectMultipleFacetFilters,

    [AT.UNSELECT_MULTIPLE_FACETS_OF_TYPE]: unselectFacetFiltersOfType,

    [AT.UNSELECT_FACET_FILTER]: unSelectFacet,

    [AT.RESELECT_FACET_FILTER]: (state, { payload: { facetToSelect, facetToUnselect } }) => {
      let newState = unSelectFacet(state, { payload: { facetFilterToRemove: facetToUnselect } });
      newState = selectFacet(newState, { payload: { selectedFacetFilter: facetToSelect } });

      return newState;
    },

    [AT.CLEAR_ALL_FILTERS]: () => initialState,

    [AT.SET_INITIAL_SEARCH_QUERY_FROM_URL_PARAMS]: (state, { payload }) => payload.facetsFilters || initialState,
  },
  initialState
);

export default selectedFacetsFiltersReducer;
