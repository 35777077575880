import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Highlight } from '../../../../components/common/highlight';
import { getQueryParameterByName } from '../../../../utils/search';

class SnippetSub extends React.Component {
  render() {
    let attributeName = this.props.attributeName;
    let query = '';
    if (typeof window !== 'undefined') {
      query = getQueryParameterByName('query');
    } else {
      query = this.props.searchQuery;
    }

    if (!this.props.result[attributeName] && attributeName === 'deck') {
      attributeName = 'blurb';
    }

    if (!this.props.result[attributeName]) {
      return null;
    }

    return (
      <Container>
        <Highlight text={this.props.result[attributeName]} textToHighlight={query} />
      </Container>
    );
  }
}

const Container = styled.div`
  font-family: 'Lab Grotesque', sans-serif;
  line-height: 1.3;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  font-style: normal;

  font-size: 14px;
  margin-top: 0px;
  width: 100%;
  color: #777;

  label {
    text-transform: capitalize;
  }
`;

SnippetSub.propTypes = {
  result: PropTypes.object.isRequired,
  attributeName: PropTypes.string.isRequired,
  includeLabel: PropTypes.bool,
  searchQuery: PropTypes.string.isRequired,
};

SnippetSub.defaultProps = {
  includeLabel: false,
};

const mapStateToProps = (state) => ({
  searchQuery: state.submittedSearchQuery,
});

export default connect(mapStateToProps)(SnippetSub);
