import * as AT from '../constants/action-types';
import 'cross-fetch/polyfill';
import config from '../config';

const relatedQueriesMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    if (!config.RELATED_QUERIES_ENABLED || action.type !== AT.SEARCH.SUCCESS) {
      return;
    }

    const state = getState();

    const query = state.currentSearchQuery;

    if (query == null) return;

    let url = `${config.RELATED_QUERIES_URL}?query=${query}`;

    const currentSearchResults = state.searchResults.results;

    if (currentSearchResults != null) {
      let documentIdList = '';

      currentSearchResults.forEach((result) => {
        if (result.objectID) {
          documentIdList = `${documentIdList}&id=${result.objectID}`;
        }
      });

      url = `${url}${documentIdList}`;
    }

    let options = {
      method: 'GET',
      headers: { Accept: 'application/json;v=1.0' },
    };

    return fetch(encodeURI(url), options)
      .then((res) => {
        if (res.status >= 400) {
          throw new Error('Bad response from server');
        }
        return res.json();
      })
      .then((data) => {
        dispatch({
          type: AT.RELATED_QUERIES.SUCCESS,
          payload: data,
        });
      })
      .catch((error) => {
        dispatch({
          type: AT.RELATED_QUERIES.FAILURE,
          payload: error,
        });
      });
  };

export default relatedQueriesMiddleware;
