import { unset } from 'lodash/fp';

import { getWindow } from '../utils/general';

import { API_REQUEST } from '../constants/action-types';

const successHandler = (dispatch, action, payload) =>
  dispatch({
    type: action.payload.actionType.SUCCESS,
    payload,
    meta: action.meta,
    requestVariables: unset('actionType', action.payload),
  });

const errorHandler = (dispatch, action, error) =>
  dispatch({
    type: action.payload.actionType.FAILURE,
    error,
    meta: action.meta,
    requestVariables: unset('actionType', action.payload),
  });

const apiMiddleware =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    if (action.type !== API_REQUEST) {
      return next(action);
    }

    dispatch({
      type: action.payload.actionType.PENDING,
      meta: action.meta,
      requestVariables: unset('actionType', action.payload),
    });

    return getWindow()
      .fetch(action.meta.url, action.meta)
      .then((response) => {
        if (!response.ok) {
          throw Error(response.statusText);
        }

        return action.isNotJsonResponse ? response : response.json();
      })
      .then((payload) => successHandler(dispatch, action, payload))
      .catch((error) => errorHandler(dispatch, action, error));
  };

export default apiMiddleware;
