import { handleActions } from 'redux-actions';
import { unset } from 'lodash/fp';

import * as AT from '../constants/action-types';

const initialState = {};

const selectedNumericFiltersReducer = handleActions(
  {
    [AT.SELECT_NUMERIC_FILTER]: (state, { payload }) => ({
      ...state,
      ...payload.selectedNumericFilter,
    }),

    [AT.UNSELECT_NUMERIC_FILTER]: (state, { payload }) => unset(payload.numericFilterToRemove, state),

    [AT.SET_INITIAL_SEARCH_QUERY_FROM_URL_PARAMS]: (state, { payload }) => payload.numericFilters || initialState,

    [AT.CLEAR_ALL_FILTERS]: () => initialState,
  },
  initialState
);

export default selectedNumericFiltersReducer;
