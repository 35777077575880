import * as AT from '../constants/action-types';
import { SORTING_OPTIONS } from '../constants/sorting-options';

import { trackEvent } from '../utils/analytics';

export const setSortingOption = (sortingOption) => {
  const eventString = sortingOption === SORTING_OPTIONS.DATE ? 'Date' : 'Best Match';

  trackEvent(`Sort-By-${eventString}`);

  return {
    type: AT.SET_SORTING_OPTION,
    payload: {
      sortingOption,
    },
  };
};
