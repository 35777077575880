import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import theme from '../../../../constants/theme';

const Type = ({ type }) => {
  const formattedType = type;

  const getTypeColor = (ty) => {
    return theme.fontColor;
  };

  return <Container typeColor={getTypeColor(type)}>{formattedType}</Container>;
};

const Container = styled.div`
  color: ${(props) => props.typeColor};
  display: inline-block;
  font-size: 12px;
  font-family: 'Lab Grotesque', sans-serif;
  line-height: 22px;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 700;
  font-style: normal;
`;

Type.propTypes = {
  type: PropTypes.string,
};

export default Type;
