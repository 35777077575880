import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { fetchAds } from '../../middlewares/ad';
import Result from '../../components/search-results/result/result';

class Results extends React.Component {
  render() {
    return (
      <Container>
        <ResultsList>
          {this.props.results.map((result) => (
            <Result key={result.objectID} result={result} />
          ))}
        </ResultsList>
      </Container>
    );
  }
}

Results.propTypes = {
  facetsFilters: PropTypes.object,
  query: PropTypes.string.isRequired,
  results: PropTypes.array.isRequired,
  fetchAds: PropTypes.func,
};

const Container = styled.div`
  margin-top: 10px;
  @media (max-width: 575px) {
    margin-top: 0;
  }
`;

const ResultsList = styled.ul`
  border-top: none;
  margin-top: 0;

  -webkit-margin-before: 0;
  -webkit-margin-after: 0;
`;

const mapStateToProps = ({ selectedFacetsFilters, currentSearchQuery }) => ({
  query: currentSearchQuery,
  facetsFilters: selectedFacetsFilters,
});

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    fetchAds: (query, facetsFilters, adUnits) => dispatch(fetchAds(query, facetsFilters, adUnits)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Results);
