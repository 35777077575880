import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import PopperTooltip from './PopperTooltip';
import { FlexContainer } from '@insiderintelligence/componentlibrary';

const PillTooltip = (props) => {
  const { name, active, children, callback, counter, tooltipProps } = props;

  const closePill = () => {
    if (active) callback();
  };

  const PillButton = (
    <TabButton onClick={callback} isActive={active || counter}>
      <TabButtonContent flexDirection="row" spaceBetween={10}>
        {name}
        {counter > 0 && <PillCounter>{counter}</PillCounter>}
        <PillToggle className="fa fa-chevron-down" isToggled={active} />
      </TabButtonContent>
    </TabButton>
  );

  return (
    <PillWrapper>
      <PopperTooltip
        showPopper={active}
        target={PillButton}
        distance={15}
        placement="bottom"
        showArrow
        controlShow
        onClickOutside={closePill}
        {...tooltipProps}
      >
        <TooltipWrapper>{children}</TooltipWrapper>
      </PopperTooltip>
    </PillWrapper>
  );
};

PillTooltip.propTypes = {
  name: PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired,
  callback: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  counter: PropTypes.number,
  tooltipProps: PropTypes.object,
};

const TabButton = styled.button`
  position: relative;
  padding: 5px 14px;
  height: 34px;
  font-size: 16px;
  font-weight:700;
  color: ${({ isActive }) => (isActive ? '#FFF' : '#333')};
  background-color: ${({ isActive }) => (isActive ? '#111111' : 'transparent')};
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  border:1px solid black
  font-style: bold;
  white-space: nowrap;
`;

const PillCounter = styled(FlexContainer)`
  width: 18px;
  height: 18px;
  background-color: white;
  font-size: 12px;
  font-weight: 900;
  color: black;
  border-radius: 50%;
`;

const TabButtonContent = styled(FlexContainer)``;

const PillToggle = styled.span`
  text-rendering: auto;
  color: ${({ theme }) => theme.facetToggleIcon};
  transform: rotate(0deg);
  transition: transform 0.5s;

  ${({ isToggled }) =>
    isToggled &&
    `
    transform-origin: 50% 50%;
    transform: rotate(180deg);
  `}
`;

const TooltipWrapper = styled.div`
  width: 320px;
  background-color: #ffffff;
  border: 1px solid #ebebeb;
  padding: 24px;
  border-radius: inherit;
`;

const PillWrapper = styled.div`
  position: relative;
`;

export default PillTooltip;
