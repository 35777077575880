import { createSelector } from 'reselect';
import { flow, keys, startsWith, last, filter, sortBy } from 'lodash/fp';
import { getFacetValueName, filterFacetsByType, getByLevel } from '../utils/facets';

import { mapWithKey } from '../utils/general';

import { FILTERS } from '../constants/facets';

export const getSelectedFacets = (state) => state.selectedFacetsFilters;

export const getFacets = (state) => state.facets;

export const getFacetsByType = (type) => (state) => filterFacetsByType(state.facets, type);

export const getFacetValuesByType = (type) => (state) => filterFacetsByType(state.facets, type)[type];

export const getSelectedFiltersByType = (type) => (state) => {
  const selected = state.selectedFacetsFilters;
  return selected && type in selected ? selected[type] : [];
};

export const getHighLevelFiltersByType = (type) =>
  createSelector(getSelectedFacets, (facets) => {
    const highLevelKeyName = getHighLevelKeyName(type, facets);

    return highLevelKeyName ? getFacetValueName(facets[highLevelKeyName]) : null;
  });

export const getFiltersByType = (type) =>
  createSelector(getSelectedFacets, (facets) => {
    const highLevelKeyName = getHighLevelKeyName(type, facets);

    return highLevelKeyName ? facets[highLevelKeyName] : null;
  });

export const getHighLevelDemographicsFilters = getHighLevelFiltersByType(FILTERS.DEMOGRAPHICS);

export const getHighLevelGeographicsFilters = getHighLevelFiltersByType(FILTERS.GEOGRAPHIES);

export const getHighLevelIndustriesFilters = getHighLevelFiltersByType(FILTERS.INDUSTRIES);

export const getHighLevelTopicsFilters = getHighLevelFiltersByType(FILTERS.TOPICS);

const getHighLevelKeyName = (facetType, facets) =>
  flow([keys, filter((facet) => startsWith(facetType, facet)), sortBy((facetName) => facetName), last])(facets);

export const checkboxData = (type) =>
  createSelector(getFacetValuesByType(type), getSelectedFiltersByType(type), (values, valueFilters) => {
    const data = [];

    mapWithKey((numOfResults, value) => {
      data.push({
        [type]: value,
        numOfResults,
        checked: false,
      });
    }, values);

    if (valueFilters) {
      valueFilters.forEach((value) => {
        const currentToLower = value.toLowerCase();
        const filtersToLower = data.map((x) => x[type].toLowerCase());
        const index = filtersToLower.indexOf(currentToLower);
        if (index >= 0) {
          data[index].checked = true;
        }
      });
    }

    return data;
  });

export const getFacetsByLevelAndType = (level, type) =>
  createSelector(getFacetsByType(type), (facets) => getByLevel(facets, level));
