const theme = {
  blue: '#2b7cac',
  red: 'red',
  buttonRed: '#e91019',
  buttonBlue: '#007EFF',
  grey: '#c9ccd1',
  htmlGrey: '#808080',
  htmlRed: '#FF0000',
  white: '#FFFFFF',
  black: '#000000',
  iconGrey: '#BFC7D3',
  hoveredTitle: '#000',
  pubDate: 'rgba(0, 0, 0, 0.54)',
  fontColor: '#111111',
  searchBoxBorder: '#ccc',
  selectedFilterBorder: '#d1d1d1',
  resultBottomBorder: 'rgba(0, 0, 0, 0.13)',
  facetToggleIcon: '#007EFF',
  title: '#111111',
  blurb: '#747474',
  titleHover: '#111111',
  titleVisited: '#5927B4',
  resultBorder: 'rgba(0, 0, 0, 0.13)',
  tooltipBackground: 'rgba(0, 0, 0, 0.87)',
  tooltipBorder: 'hsla(0, 1%, 46%, .68)',
  date: 'rgba(0, 0, 0, 0.54)',
  iiBlue: '#007eff',
};

export default theme;
