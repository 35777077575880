import moment from 'moment/moment';
import config from '../config';
import { PUBLIC_AUDIENCE } from './config';

// We do not capitalize these words.
export const FACET_CASE_EXCEPTIONS = ['by', 'of', 'on', 'per', 'with'];

export const FACET_TABS = {
  analysisFilter: ['article', 'report', 'interview', 'audio', 'video'],
  chartFilter: ['forecasts', 'chart', 'kpi'],
};

export const DATE_FILTERS = [
  {
    shownText: 'Within last 90 days',
    daysToSubtract: 90,
  },
  {
    shownText: 'Within last 180 days',
    daysToSubtract: 180,
  },
  {
    shownText: 'Within last year',
    daysToSubtract: 365,
  },
  {
    shownText: 'All Publication Dates',
  },
];

export const FACETS = [
  {
    key: 'GEOGRAPHIES',
    title: 'Geography',
    visibility: [PUBLIC_AUDIENCE],
    filterField: 'geographies',
    initialState: { 'geographies.lvl0': {} },
    rootSelectable: true,
  },
  {
    key: 'TYPE',
    title: 'Content Type',
    visibility: [PUBLIC_AUDIENCE],
    filterField: 'type',
    initialState: { type: {} },
    multiSelect: true,
    rootSelectable: true,
  },
  {
    key: 'TOPICS',
    title: 'Topic',
    visibility: [PUBLIC_AUDIENCE],
    filterField: 'topics',
    initialState: { 'topics.lvl0': {} },
    rootSelectable: true,
  },
  {
    key: 'INDUSTRIES',
    title: 'Industry',
    visibility: [PUBLIC_AUDIENCE],
    filterField: 'industries',
    initialState: { 'industries.lvl0': {} },
    rootSelectable: true,
  },
  {
    key: 'DEMOGRAPHICS',
    title: 'Demographics',
    visibility: [PUBLIC_AUDIENCE],
    filterField: 'demographics',
    initialState: { 'demographics.lvl0': {} },
    rootSelectable: true,
  },
  {
    key: 'KEYWORDS',
    title: 'Keywords',
    visibility: [],
    filterField: 'keywords',
    initialState: { keywords: {} },
    multiSelect: true,
    rootSelectable: true,
  },
  {
    key: 'USERS',
    title: 'User',
    visibility: [],
    filterField: 'user_name',
    initialState: { user_name: {} },
    multiSelect: true,
    rootSelectable: true,
  },
  {
    key: 'GROUPS',
    title: 'Group',
    visibility: [],
    filterField: 'group_name',
    initialState: { group_name: {} },
    multiSelect: true,
    rootSelectable: true,
  },
  {
    key: 'PUBLISHED_DATE',
    title: 'Publication Date',
    visibility: [PUBLIC_AUDIENCE],
    filterField: 'published_date',
    initialState: { published_date: {} },
    rootSelectable: true,
  },
];

//Filter names:  Map of { <key>:<filterField> }
export const FILTERS = FACETS.reduce((map, f) => Object.assign(map, { [f.key]: f.filterField }), {});

export const WEEKLY_ALERT_FACETS = {
  type: 'AssetTypes',
  demographics: 'Demographics',
  geographies: 'Geographies',
  industries: 'Industries',
  topics: 'Topics',
  query: 'Keywords',
};

export const DEFAULT_WEEKLY_ALERT_PAYLOAD = {
  [WEEKLY_ALERT_FACETS.type]: [],
  [WEEKLY_ALERT_FACETS.demographics]: [],
  [WEEKLY_ALERT_FACETS.geographies]: [],
  [WEEKLY_ALERT_FACETS.industries]: [],
  [WEEKLY_ALERT_FACETS.topics]: [],
};

export const defaultNumericFilters = () => {
  if (config.DEFAULT_DATE_FILTER_ENABLED) {
    return {
      published_date: {
        from: moment().subtract(config.MAX_MONTHS, 'months').unix(),
        to: moment().unix(),
      },
    };
  }

  return {};
};
