import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

class CompanyName extends React.Component {
  render() {
    const name = this.props.name;

    if (!name) {
      return null;
    }

    return <Container>{name}</Container>;
  }
}

const Container = styled.div`
  font-family: 'Lab Grotesque', sans-serif;
  line-height: 1.4;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  font-style: normal;

  color: #777;
  font-size: 16px;
  margin-top: 0px;
  width: 100%;
`;

CompanyName.propTypes = {
  name: PropTypes.string,
};

export default CompanyName;
