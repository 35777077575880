import { handleActions } from 'redux-actions';

import * as AT from '../constants/action-types';

const initialState = '';

const currentSearchQueryReducer = handleActions(
  {
    [AT.SET_SEARCH_QUERY]: (state, { payload }) => payload.query || '',

    [AT.SET_INITIAL_SEARCH_QUERY_FROM_URL_PARAMS]: (state, { payload }) => payload.query || initialState,
  },
  initialState
);

export default currentSearchQueryReducer;
