import React from 'react';
import { words, sortBy, isEmpty } from 'lodash/fp';

import BoldField from '../../components/common/bold-field';

const Highlight = ({ text, textToHighlight }) => {
  const searchWords = words(textToHighlight);
  let indexesToHighlight = [];

  searchWords.forEach((word) => {
    let startIndex = text.toLowerCase().indexOf(word.toLowerCase(), 0);

    while (startIndex >= 0) {
      indexesToHighlight = [...indexesToHighlight, { start: startIndex, end: startIndex + word.length }];
      startIndex += word.length;
      startIndex = text.toLowerCase().indexOf(word.toLowerCase(), startIndex);
    }
  });

  if (isEmpty(indexesToHighlight)) {
    return text;
  }

  let strings = [];
  let currentIndex = 0;
  let key = 0;

  indexesToHighlight = sortBy('start', indexesToHighlight);
  ClearDedupes(indexesToHighlight);

  indexesToHighlight.forEach((indexes) => {
    if (indexes.end > currentIndex) {
      strings = [...strings, <span key={key++}>{text.slice(currentIndex, indexes.start)}</span>];
      strings = [...strings, <BoldField key={key++}>{text.slice(indexes.start, indexes.end)}</BoldField>];
      currentIndex = indexes.end;
    }
  });

  return [...strings, <span key={key}>{text.slice(currentIndex)}</span>];
};

function ClearDedupes(dupedArr) {
  if (dupedArr.length > 1) {
    for (let i = 0; i < dupedArr.length; i++) {
      for (let j = 0; j < dupedArr.length; j++) {
        //base case
        if (i === j) continue;

        if (dupedArr[i].start > dupedArr[j].start && dupedArr[i].end < dupedArr[j].end) {
          //j includes i so make i disappear
          dupedArr[i].start = dupedArr[i].end;
        } else if (dupedArr[j].start > dupedArr[i].start && dupedArr[j].end < dupedArr[i].end) {
          //i includes j so make j disappear
          dupedArr[j].start = dupedArr[j].end;
        } else if (dupedArr[j].end > dupedArr[i].end) {
          //j is ends later. check if i starts between j's start and end point
          if (dupedArr[i].start >= dupedArr[j].start && dupedArr[i].start <= dupedArr[j].end) {
            //we found our dupe. make j's start point after i
            dupedArr[j].start = dupedArr[i].end;
          }
        } else if (dupedArr[i].end > dupedArr[j].end) {
          if (dupedArr[j].start >= dupedArr[i].start && dupedArr[j].start <= dupedArr[i].end) {
            dupedArr[i].start = dupedArr[j].end;
          }
        } else if (dupedArr[i].end === dupedArr[j].end) {
          if (dupedArr[i].start === dupedArr[i].end || dupedArr[j].start === dupedArr[j].end) {
            continue;
          }
          if (dupedArr[i].start >= dupedArr[j].start && dupedArr[i].start <= dupedArr[j].end) {
            dupedArr[i].start = dupedArr[j].end;
          } else if (dupedArr[j].start >= dupedArr[i].start && dupedArr[j].start <= dupedArr[i].end) {
            dupedArr[j].start = dupedArr[i].end;
          }
        }
      }
    }
  }
}

export { Highlight, ClearDedupes };
