import * as AT from '../constants/action-types';
import PropTypes from 'prop-types';
import React, { createRef, useEffect, useState } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import config from '../config';
import TopBar from './topbar';
import Head from './head';
import SearchBox from '../components/search-box';
import SearchResults from './search-results';
import { isOnBrowser } from '../utils/general';
import { buildSearchQuery } from '../utils/query';
import { getQueryParameterByName } from '../utils/search';
import { Branding, Footer, useWindowSize } from '@insiderintelligence/componentlibrary';
import {
  AdBanner,
  AD_SLOT_TYPE__TOP,
  AD_SLOT_TYPE__MIDDLE_FLUID1,
  AD_SLOT_TYPE__MIDDLE_FLUID2,
  useGAM,
} from '@insiderintelligence/googleadmanager';
import Cookies from 'js-cookie';
import { AppContext } from './AppContext';
import { MOBILE } from '../constants/responsive-breakpoints';
import {
  DATA_LAYER_TYPE_ACCESS_TYPE__PUB,
  DATA_LAYER_TYPE_SECTION__SEARCH,
  DATA_LAYER_TYPE_UID_PREFIX_SEARCH,
  DATA_LAYER_TYPE_PAYWALL__NONE,
  getUserSubscriptionTierFromCookies,
  dataLayerRawPush,
} from '@insiderintelligence/datalayer';

const Root = ({
  query,
  facetsFilters,
  numericFilters,
  sortingOption,
  page,
  onClientLoad,
  sideRailAssets,
  currentSearchQuery,
}) => {
  const [trigger, setTrigger] = useState('');
  const [scrolled, setScrolled] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const size = useWindowSize();

  useEffect(() => {
    setIsMobile(size.width <= MOBILE);
  }, [size]);

  const searchRef = createRef();

  useEffect(() => {
    // Visually set the search query in the top bar
    document.getElementById('emarketer-typeahead-host').getElementsByTagName('input')[0].value = currentSearchQuery;
  }, [currentSearchQuery]);

  let handleUrlChange = (e) => {
    if (isOnBrowser()) {
      setTrigger(String(Math.random()));
    }
  };

  if (isOnBrowser()) {
    buildSearchQuery(getQueryParameterByName('query'), facetsFilters, numericFilters, sortingOption, page);
  }

  onClientLoad();
  useEffect(() => {
    window.onpopstate = handleUrlChange;
  });

  let findFilterValue = (filterObj, term) => {
    let key = Object.keys(filterObj).find((key) => {
      return key.includes(term);
    });
    return filterObj[key];
  };

  let pageTopics = [];

  if (findFilterValue(facetsFilters, 'topics')) {
    pageTopics.push(findFilterValue(facetsFilters, 'topics'));
  }

  if (findFilterValue(facetsFilters, 'geographies')) {
    pageTopics.push(findFilterValue(facetsFilters, 'geographies'));
  }

  if (findFilterValue(facetsFilters, 'industries')) {
    pageTopics.push(findFilterValue(facetsFilters, 'industries'));
  }

  if (findFilterValue(facetsFilters, 'demographics')) {
    pageTopics.push(findFilterValue(facetsFilters, 'demographics'));
  }

  const adSlotsConfig = [
    {
      adSlotType: AD_SLOT_TYPE__TOP,
    },
    {
      adSlotType: AD_SLOT_TYPE__MIDDLE_FLUID1,
      isManualFetch: true, // since search results are async, this should only be fetched manually in results grid only after the results are available
    },
    {
      adSlotType: AD_SLOT_TYPE__MIDDLE_FLUID2,
      isManualFetch: true, // since search results are async, this should only be fetched manually in results grid only after the results are available
    },
  ];

  useEffect(() => {
    const onScroll = () => {
      const scrollY = window.scrollY;
      if (!scrolled && scrollY >= 160) {
        setScrolled(true);
      } else if (scrolled && scrollY <= 160) {
        setScrolled(false);
      }
    };
    window.addEventListener('scroll', onScroll);
    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, [scrolled]);

  useGAM(
    {
      gptScriptUrl: config.COMMON_GAM_GPT_SCRIPT_URL,
      microserviceS1Id: config.GAM_CURRENT_MICROSERVICE_S1_ID,
      networkId: config.COMMON_GAM_NETWORK_ID,
      parentAdUnitId: config.COMMON_GAM_PARENT_AD_UNIT_ID,
      testAdContainer: config.COMMON_GAM_TEST_AD_CONTAINER_ID,
      adSlots: adSlotsConfig,
      userEmailDomain: Cookies.get(config.COMMON_USER_EMAILDOMAIN_COOKIE),
      searchKeyword: query,
      pageTopic: pageTopics.length > 0 ? pageTopics : undefined,
    },
    config.CURRENT_ENVIRONMENT
  );

  useEffect(() => {
    if (isOnBrowser()) {
      dataLayerRawPush({
        accessType: DATA_LAYER_TYPE_ACCESS_TYPE__PUB,
        section: DATA_LAYER_TYPE_SECTION__SEARCH,
        unifiedAssetId: `${DATA_LAYER_TYPE_UID_PREFIX_SEARCH}index`,
        paywallType: DATA_LAYER_TYPE_PAYWALL__NONE,
        isAdBlock: false,
        isLocked: false,
        userSubscriptionTier: getUserSubscriptionTierFromCookies(document.cookie),
        keywords: query,
      });
    }
  }, [query]);

  const brandingProps = {};
  return (
    <div ref={searchRef}>
      <AppContext.Provider
        value={{
          scrolled,
          isMobile,
        }}
      >
        <Head />
        <div id="emarketer-masthead-host">
          <Branding
            enableRedirectionToProSiteOnLogin={true}
            articlesServiceUrl={config.COMMON_ARTICLES_SERVICE_URL}
            iiHomeUrl={config.COMMON_BASE_URL}
            researchTopicsServiceUrl={config.COMMON_RESEARCH_TOPICS_SERVICE_URL}
            searchServiceUrl={config.SEARCH_BASE_URL}
            noteworthyContentInjectable={config.COMMON_NOTEWORTHY_SERVICE_URL}
            noteworthyContentApiUrl={config.COMMON_NOTEWORTHY_API_URL}
            typeaheadPluginUrl={config.COMMON_TYPEAHEAD_SERVICE_URL}
            queryStringKeeperServiceUrl={config.COMMON_QUERYSTRING_KEEPER_URL}
            subscriptionsLoginUrl={config.COMMON_SUBSCRIPTIONS_SERVICE_URL}
            osanoScriptUrl={config.COMMON_OSANO_SCRIPT_URL}
            gaApiSecret={config.COMMON_GA_API_SECRET}
            gaMeasurementId={config.COMMON_GA_MEASUREMENT_ID}
            doesMyCompanyURL={config.SUBSCRIPTIONS_DOES_MY_COMPANY_SUBSCRIBE_PLUGIN_URL}
            coverageRightRailItems={sideRailAssets}
            {...brandingProps}
          />
        </div>
        <MobileSearchContainer>
          <SearchBox key={trigger} />
        </MobileSearchContainer>
        <TopContainer scrolled={scrolled}>
          <TopBar query={currentSearchQuery} />
        </TopContainer>
        <ResultsContainer scrolled={scrolled}>
          <ResultsWrapper>
            <AdBannerWrapper>
              <AdBanner adSlotType={AD_SLOT_TYPE__TOP} />
            </AdBannerWrapper>
            {
              // submittedSearchQuery ? (
              //   <ResultsSuggestionsWrapper>
              //     <ResultsSuggestionQuery>
              //       Showing results for <Bold>{currentSearchQuery}</Bold>
              //     </ResultsSuggestionQuery>
              //     <ResultsSuggestion>
              //       Did you mean <a href={``}>{currentSearchQuery}</a> ?
              //     </ResultsSuggestion>
              //   </ResultsSuggestionsWrapper>
              // ) : null
            }
            <SearchResults />
          </ResultsWrapper>
        </ResultsContainer>
        <Footer
          articlesServiceUrl={config.COMMON_ARTICLES_SERVICE_URL}
          contentUrl={config.COMMON_CONTENT_SERVICE_URL}
          iiHomeUrl={config.COMMON_BASE_URL}
          numbersUrl={config.COMMON_FORECASTS_SERVICE_URL}
          researchTopicsServiceUrl={config.COMMON_RESEARCH_TOPICS_SERVICE_URL}
          searchServiceUrl={config.SEARCH_BASE_URL}
          osfScriptUrl={config.COMMON_OSF_SCRIPT_URL}
        />
      </AppContext.Provider>
    </div>
  );
};

Root.propTypes = {
  query: PropTypes.string.isRequired,
  facetsFilters: PropTypes.object.isRequired,
  numericFilters: PropTypes.object.isRequired,
  sortingOption: PropTypes.string.isRequired,
  page: PropTypes.number.isRequired,
  onClientLoad: PropTypes.func.isRequired,
  sideRailAssets: PropTypes.array.isRequired,
  currentSearchQuery: PropTypes.string.isRequired,
};

// This is needed so that the mobile experience stays the same as
// eMarketer

const MobileSearchContainer = styled.div`
  display: block;
  margin-bottom: 20px;
  @media (min-width: 1024px) {
    display: none;
  }
`;

const AdBannerWrapper = styled.div`
  margin-top: 15px;
  margin-bottom: 0;

  @media (max-width: 1023px) {
    margin-top: -40px;
    margin-bottom: 8px;
  }
`;

const TopContainer = styled.div`
  background-color: #f5f5f5;
  min-height: 195px;
  padding: 0 30px;
  @media (max-width: 768px) {
    padding: 0;
    min-height: 131px;
  }
  ${({ scrolled }) =>
    scrolled &&
    `
    min-height: 130px;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    @media (min-width: 769px) {
      top: 110px;
    }
  `}
`;

const ResultsContainer = styled.div`
  width: 100%;
  padding: 25px 30px 30px 30px;
  @media (min-width: 320px) {
    font-size: 18px;
  }
  @media (min-width: 768px) {
    font-size: 16px;
  }
  ${({ scrolled }) =>
    scrolled &&
    `
    padding-top: 190px;
  `}
`;

const ResultsWrapper = styled.div`
  margin: 0 auto;
  max-width: 1015px;
  width: 100%;
`;

const mapStateToProps = (state) => {
  return {
    query: state.currentSearchQuery,
    currentSearchQuery: state.currentSearchQuery,
    // submittedSearchQuery: state.submittedSearchQuery,
    facetsFilters: state.selectedFacetsFilters,
    numericFilters: state.selectedNumericFilters,
    sortingOption: state.sortingOption,
    page: state.page,
    ad: state.ads.adUnits['Top1'],
    sideRailAssets: state.brandingSideRail.results,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onClientLoad: () => dispatch({ type: AT.PAGE_LOADED }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Root);
