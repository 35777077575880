import qs from 'qs';

import { setSource } from '../actions/source';
import { getQueryParameterByName } from './search';
import { isOnBrowser } from '../utils/general';
import { FACETS } from '../constants/facets';

export const buildSearchQueryString = (searchObject) => {
  const { query, facetsFilters, numericFilters, sortingOption, page } = searchObject;
  const queryObject = {};
  const app = isOnBrowser() ? getQueryParameterByName('app') : '';
  const location = isOnBrowser() ? getQueryParameterByName('location') : '';

  if (page > 1) {
    queryObject.page = page;
  }

  if (query) {
    queryObject.query = query;
  }

  if (sortingOption) {
    queryObject.sortBy = sortingOption;
  }

  if (facetsFilters && facetsFilters !== {}) {
    let hierarchicalMenu = {};

    for (var menu in facetsFilters) {
      if (menu.includes('.lvl') && facetsFilters.hasOwnProperty(menu)) {
        if (facetsFilters[menu]) {
          hierarchicalMenu[`${menu.split('.')[0]}.lvl0`] = facetsFilters[menu];
        }
      }
    }

    queryObject.hierarchicalMenu = hierarchicalMenu;

    queryObject.refinementList = FACETS.filter(
      (f) =>
        'multiSelect' in f && // multi-select facets go in refinementList param
        f.multiSelect &&
        f.filterField in facetsFilters // if they are selected
    )
      .map((f) => f.filterField)
      .reduce((refinements, filterField) => {
        return Object.assign(refinements, { [filterField]: facetsFilters[filterField] });
      }, {});
  }

  if (numericFilters && numericFilters !== {}) {
    queryObject.multiRange = {};
    if (numericFilters.published_date) {
      queryObject.multiRange.published_date = `${numericFilters.published_date.from}:${numericFilters.published_date.to}`;
    }
  }

  if (app) {
    queryObject.app = app;
  }

  if (location) {
    queryObject.location = location;
  }

  return qs.stringify(queryObject, { indices: false });
};

export const buildSearchQuery = (query, facetsFilters, numericFilters, sortingOption, page) => {
  const searchString = buildSearchQueryString({ query, facetsFilters, numericFilters, sortingOption, page });
  const questionMark = searchString && searchString.length > 0 ? '?' : '';
  const path = window.location.pathname ? window.location.pathname : '';
  const address = `${window.location.protocol}//${window.location.host}${path}${questionMark}${searchString}`;
  if (address !== window.location.href) {
    window.history.pushState('', '', address);
  }
};

export const setSearchSource = (query, dispatch) => {
  const queryString = qs.parse(query, { ignoreQueryPrefix: true });
  if (queryString.app && queryString.location) {
    dispatch(setSource(queryString.app, queryString.location));
  }
};
