import React from 'react';
import BoldField from '../../../../components/common/bold-field';
import { PropTypes } from 'prop-types';

const User = ({ user }) => {
  return <BoldField> – {user}</BoldField>;
};

User.propTypes = {
  user: PropTypes.string.isRequired,
};

export default User;
