import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { trackEvent } from 'utils/analytics';

import { selectMultipleFacetFilters as selectMultipleFilters } from '../../actions/selected-facets-filters';
import { unselectFacetFiltersOfType as unselectMultipleFilters } from '../../actions/selected-facets-filters';

import { FACET_TABS } from '../../constants/facets';
import { FILTERS } from '../../constants/facets';
import Tab from '../reusable-components/molecules/Tab';

const FacetFilterTabs = (props) => {
  const { selectMultipleFacetFilters, unselectFacetFiltersOfType, selectedFacetsFilters } = props;

  const applyAllFilter = () => {
    unselectFacetFiltersOfType(FILTERS.TYPE);
    trackEvent(`Tab-All`);
  };

  const applyAnalysisFilter = () => {
    if (isFilter(FACET_TABS.analysisFilter)) {
      return;
    }
    selectMultipleFacetFilters({ type: FACET_TABS.analysisFilter });
    trackEvent(`Tab-Analysis`);
  };

  const applyChartFilter = () => {
    if (isFilter(FACET_TABS.chartFilter)) {
      return;
    }
    selectMultipleFacetFilters({ type: FACET_TABS.chartFilter });
    trackEvent(`Tab-Data`);
  };

  const isFilter = (_arr) => {
    const types = selectedFacetsFilters.type;
    if (types) {
      if (!Array.isArray(types) || !Array.isArray(_arr) || types.length !== _arr.length) {
        return false;
      }
      const arr1 = types.concat().sort();
      const arr2 = _arr.concat().sort();
      for (let i = 0; i < arr1.length; i++) {
        if (arr1[i] !== arr2[i]) return false;
      }
      return true;
    }
    return false;
  };

  const noFilter = selectedFacetsFilters.type === undefined || null;

  return (
    <Container>
      <TabWrapper>
        <Tab name={'All'} callback={applyAllFilter} active={noFilter} disableTooltip />
        <Tab
          name={'Analysis'}
          callback={applyAnalysisFilter}
          active={isFilter(FACET_TABS.analysisFilter)}
          tooltip={'Results for Insights in Written, Audio, and Video formats'}
        />
        <Tab
          name={'Data'}
          callback={applyChartFilter}
          active={isFilter(FACET_TABS.chartFilter)}
          tooltip={'Results for Charts, Forecasts, and Industry KPIs'}
        />
      </TabWrapper>
    </Container>
  );
};

const TabWrapper = styled.div`
  display: flex;
  font-family: 'Lab Grotesque', sans-serif;
  line-height: 1.4;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  font-style: normal;
  color: ${({ active }) => (!active ? '#777777' : 'black')};

  @media (max-width: 768px) {
    display: none;
  }
`;

const Container = styled.div`
  display: inline-block;
`;

FacetFilterTabs.propTypes = {
  selectedFacetsFilters: PropTypes.object,
  selectMultipleFacetFilters: PropTypes.func,
  unselectFacetFiltersOfType: PropTypes.func,
};

const mapStateToProps = ({ selectedFacetsFilters }) => ({
  selectedFacetsFilters,
});

export default connect(mapStateToProps, {
  selectMultipleFacetFilters: selectMultipleFilters,
  unselectFacetFiltersOfType: unselectMultipleFilters,
})(FacetFilterTabs);
