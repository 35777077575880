import * as AT from '../constants/action-types';
import logger from '../logger';
import { fetchAssetsForBranding } from '@insiderintelligence/componentlibrary';

const brandingAssetsMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);
    const state = getState();
    if (action.type === AT.SET_BRANDING_SIDE_RAIL.FAILURE) {
      return;
    }

    // only execute this if there are no branding side rail items on state.
    if (!state.brandingSideRail?.results) {
      return fetchAssetsForBranding(
        process.env.COMMON_DYNAMIC_CONTENT_WEBAPI,
        process.env.COMMON_DYNAMIC_CONTENT_USER,
        process.env.COMMON_DYNAMIC_CONTENT_PASSWORD,
        fetch,
        logger
      )
        .then((data) => {
          dispatch({
            type: AT.SET_BRANDING_SIDE_RAIL.SUCCESS,
            payload: data,
          });
        })
        .catch((ex) => {
          dispatch({
            type: AT.SET_BRANDING_SIDE_RAIL.FAILURE,
            payload: ex,
          });
        });
    } else {
      // items already on state, just continue.
      return;
    }
  };

export default brandingAssetsMiddleware;
