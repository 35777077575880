import uuidV4 from 'uuid/v4';
import { setSearchId } from '../actions/search-id';
import { isEqual } from 'lodash/fp';
import * as AT from '../constants/action-types';
import { RESULTS_PER_PAGE } from '../constants/query';

const getSearchState = (state) => {
  return {
    query: state.currentSearchQuery,
    facetsFilters: state.selectedFacetsFilters,
    numericFilters: state.selectedNumericFilters,
    sortingOption: state.sortingOption,
    resultsPerPage: RESULTS_PER_PAGE,
  };
};

const searchIdMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    if (action.type === AT.PAGE_LOADED) {
      dispatch(setSearchId(uuidV4()));
      return next(action);
    }

    const oldState = getState();
    const oldSearchState = getSearchState(oldState);

    let result = next(action);

    const newState = getState();

    const newSearchState = getSearchState(newState);

    if (!isEqual(newSearchState, oldSearchState)) {
      dispatch(setSearchId(uuidV4()));
    }

    return result;
  };

export default searchIdMiddleware;
