import { standardizeCategory } from '../utils/ad/StandardizeCategory';
import config from '../config';
import { getFacetValueName } from '../utils/facets';
import { LOAD_ADS_SUCCESS, LOAD_ADS_FAIL } from '../constants/action-types';
import { someWithKey } from '../utils/general';
import { FILTERS } from '../constants/facets';
import Cookies from 'js-cookie';
import { isOnBrowser } from '../utils/general';
import logger from '../logger';

const sanitizeKeywords = (query, selectedFacetsFilters) => {
  let topics = [];
  let categories = '';
  let emailDomain = '';
  let emailDomainCookie = null;

  if (isOnBrowser()) {
    emailDomainCookie = Cookies.get('emaildomain');
  }

  if (query !== '') {
    topics.push(query);
  }

  if (selectedFacetsFilters !== undefined) {
    let topicFilterName;

    let hasTopicFilter = someWithKey((filterValue, filterName) => {
      const isIncluding = filterName.includes(FILTERS.TOPICS);

      if (isIncluding) {
        topicFilterName = filterName;
      }

      return isIncluding;
    }, selectedFacetsFilters);

    if (hasTopicFilter) {
      topics.push(getFacetValueName(selectedFacetsFilters[topicFilterName]));
    }

    if (emailDomainCookie) {
      emailDomain = emailDomainCookie;
    }

    if (topics) {
      topics.forEach((element) => {
        categories = `${categories}${standardizeCategory(element)},`;
      });

      if (!emailDomain) {
        categories = categories.slice(0, -1);
      }
    }

    if (emailDomain) {
      emailDomain = `${emailDomain.replace('.', '')}`;
    }

    return `${categories}${emailDomain}`;
  }
};

const generateAdPromise = (unit, url) =>
  fetch(url, {
    method: 'GET',
    referrerPolicy: 'no-referrer-when-downgrade',
  })
    .then((response) => response.json())
    .then((data) => ({
      adUnitTag: unit,
      adObj: data,
    }));

export const fetchAds = (query, selectedFacetsFilters, units) => {
  return (dispatch) => {
    let sanitizedKeywords = sanitizeKeywords(query, selectedFacetsFilters);
    let adPromises = [];
    units.forEach((unit) => {
      let unitWithSpecs = config.AD_TAG_SPECS.filter((x) => x.Tag === unit)[0];
      let url = `${config.AD_SERVER_URL}/adserve/;ID=${config.AD_NETWORK_ID};size=${unitWithSpecs.Size};setID=${unitWithSpecs.Id};type=json;kw=${sanitizedKeywords};click=CLICK_MACRO_PLACEHOLDER`;
      adPromises.push(generateAdPromise(unit, url));
    });

    Promise.all(adPromises)
      .then((responses) => {
        let ads = [];
        responses.forEach((response) => {
          if (response.adObj && response.adObj.status !== 'SUCCESS') {
            ads[response.adUnitTag] = '';
          } else {
            let ad = response.adObj.placements.placement_1;
            const accompaniedHtml = ad.accompanied_html;
            let html = ad.body
              ? `<a target="${ad.target}" href="${ad.redirect_url}" rel="noreferrer">${ad.body}</a>`
              : `<a target="${ad.target}" href="${ad.redirect_url}" rel="noreferrer"><img src="${ad.image_url}" alt="${ad.alt_text}" height="${ad.height}" width="${ad.width}"></img></a>`;
            if (accompaniedHtml) html += accompaniedHtml;
            ads[response.adUnitTag] = html;
          }
        });
        dispatch({
          type: LOAD_ADS_SUCCESS,
          result: {
            ads,
            topics: sanitizedKeywords.split(','),
          },
        });
      })
      .catch((error) => {
        logger.error(error);
        dispatch({
          type: LOAD_ADS_FAIL,
          result: [],
        });
      });
  };
};
