import { map, omitBy, forEach, some } from 'lodash/fp';
import { CookieReader } from '../utils/cookie-reader';

export const mapWithKey = map.convert({ cap: false });
export const omitByWithkey = omitBy.convert({ cap: false });
export const forEachWithKey = forEach.convert({ cap: false });
export const someWithKey = some.convert({ cap: false });

// For ssr support
export const getWindow = () =>
  isOnBrowser()
    ? window
    : {
        addEventListener: () => null,
        innerWidth: 900,
        fetch: require('node-fetch'),
      };

export const isOnBrowser = () => typeof window === 'object';

export const sortKeysAlphabetic = (objectToSort) => {
  const orderedObject = {};

  Object.keys(objectToSort)
    .sort()
    .forEach((key) => (orderedObject[key] = objectToSort[key]));

  return orderedObject;
};

export const isLoggedIn = () => {
  return isOnBrowser() && CookieReader('em_at', document.cookie);
};
