import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import PopperTooltip from './PopperTooltip';

const Tab = (props) => {
  const [hover, setHover] = useState(false);
  const { name, tooltip, active, callback, disableTooltip, className } = props;

  const TooltipButton = (
    <TabButton
      onClick={callback}
      isActive={active}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      {name}
    </TabButton>
  );

  return (
    <TabWrapper isActive={active} className={className}>
      <PopperTooltip
        controlShow
        showArrow
        showPopper={disableTooltip ? false : hover}
        target={TooltipButton}
        placement="bottom"
        distance={10}
        arrowClass="tab-arrow"
      >
        {tooltip ? <TooltipWrapper>{tooltip}</TooltipWrapper> : null}
      </PopperTooltip>
    </TabWrapper>
  );
};

Tab.propTypes = {
  name: PropTypes.string.isRequired,
  tooltip: PropTypes.string,
  active: PropTypes.bool.isRequired,
  callback: PropTypes.func.isRequired,
  callover: PropTypes.func,
  disableTooltip: PropTypes.bool,
  className: PropTypes.string,
};

const TabButton = styled.span`
  text-align: center;
  text-decoration: none;
  display: inline-block;
  position: relative;
  font-size: 12px;
  font-weight: 700;
  width: 110px;
  border: none;
  cursor: pointer;
  padding-bottom: 4px;

  ${({ isActive, theme }) =>
    isActive &&
    `
    color: ${theme.fontColor};
    cursor: default;
  `}
`;

const TooltipWrapper = styled.div`
  width: 150px;
  font-size: 12px;
  color: #fff;
  background-color: #000;
  padding: 10px;
  border-radius: 10px;
`;

const TabWrapper = styled.div`
  position: relative;
  margin-bottom: -1px;

  ${({ isActive, theme }) =>
    isActive &&
    `
    border-bottom: 2px solid ${theme.iiBlue};
  `}
`;

export default Tab;
