// This will be a temporary array of premium forecasts until this is properly indexed into elastic search
export const premiumForecasts = [
  { name: 'Total Media Ad Spending', collectionId: '584b26021403070290f93a2f', templateId: '59652cd00da12c0424803964' },
  {
    name: 'Total Media Ad Spending Growth, by Media',
    collectionId: '584b26021403070290f93a2f',
    templateId: '5851918b0626310a2c186b4a',
  },
  { name: 'Digital Ad Spending', collectionId: '584b26021403070290f93a56', templateId: '5851918a0626310a2c1869c4' },
  { name: 'Display Ad Spending', collectionId: '584b26021403070290f93a58', templateId: '5851918a0626310a2c1869e8' },
  {
    name: 'Display Ad Spending Growth, by Format',
    collectionId: '584b26021403070290f93a58',
    templateId: '5851918a0626310a2c1869e9',
  },
  { name: 'Search Ad Spending', collectionId: '584b26021403070290f93a59', templateId: '5851918b0626310a2c186ac8' },
  {
    name: 'Search Ad Revenue Growth, by Company',
    collectionId: '584b26021403070290f93a59',
    templateId: '5851918a0626310a2c186ac5',
  },
  {
    name: 'Total Media Ad Spending - TV Ad Spending',
    collectionId: '584b26021403070290f93a2f',
    templateId: '5851918b0626310a2c186b55',
  },
  {
    name: 'Total Media Ad Spending - Radio Ad Spending',
    collectionId: '584b26021403070290f93a2f',
    templateId: '5851918a0626310a2c186ab5',
  },
  {
    name: 'Total Media Ad Spending - Newspaper Ad Spending',
    collectionId: '584b26021403070290f93a2f',
    templateId: '5851918a0626310a2c186a90',
  },
  {
    name: 'Total Media Ad Spending - Magazine Ad Spending',
    collectionId: '584b26021403070290f93a2f',
    templateId: '5851918a0626310a2c186a34',
  },
  {
    name: 'Total Media Ad Spending - Out-of-Home Ad Spending',
    collectionId: '584b26021403070290f93a2f',
    templateId: '5851918a0626310a2c186a9b',
  },
  {
    name: 'Traditional Media Ad Spending - TV Ad Spending',
    collectionId: '5c66d06c8920aa05b8b0e35a',
    templateId: '5851918b0626310a2c186b55',
  },
  {
    name: 'Radio + Digital Audio Services Ad Spending - Radio Ad Spending',
    collectionId: '5f6d0b2085702e1f403f2c41',
    templateId: '5851918a0626310a2c186ab5',
  },
  { name: 'Out-of-Home Ad Spending', collectionId: '5f6d0b2185702e1f403f2c42', templateId: '5851918a0626310a2c186a9b' },
  {
    name: 'Total Media Ad Spending - Digital Ad Spending',
    collectionId: '584b26021403070290f93a2f',
    templateId: '5851918a0626310a2c1869c4',
  },

  {
    name: 'Amazon - OTT Ad Revenues',
    collectionId: '5911eeb5aeb8830e3829e285',
    templateId: '6543a842d05bf2db4beccac6',
  },
  {
    name: 'Amazon - Total Ad Revenues',
    collectionId: '5911eeb5aeb8830e3829e285',
    templateId: '59e8edccbfce890eb411ef5b',
  },
  {
    name: 'Connected TV Ad Spending',
    collectionId: '584b26021403070290f93a3a',
    templateId: '5d9671494945300bf4895bf9',
  },
  {
    name: 'Digital Ad Spending - Display Ad Spending',
    collectionId: '584b26021403070290f93a56',
    templateId: '5851918a0626310a2c1869e8',
  },
  {
    name: 'Digital Ad Spending - Search Ad Spending',
    collectionId: '584b26021403070290f93a56',
    templateId: '5851918b0626310a2c186ac8',
  },
  {
    name: 'Digital Audio Services Ad Spending',
    collectionId: '643483874038ec8ca528ad2f',
    templateId: '5efce1aa83c627071411ab8c',
  },
  {
    name: 'Digital Audio Services Ad Spending - iHeartMedia Digital Ad Revenues',
    collectionId: '643483874038ec8ca528ad2f',
    templateId: '6054d768d6480d089c603b44',
  },
  {
    name: 'Digital Audio Services Ad Spending - Pandora Ad Revenues',
    collectionId: '643483874038ec8ca528ad2f',
    templateId: '5f8e3d078b63e9055c1aa484',
  },
  {
    name: 'Digital Audio Services Ad Spending - Spotify - Spotify Ad Revenues',
    collectionId: '643483874038ec8ca528ad2f',
    templateId: '5c65f47b8920aa03ec257466',
  },
  {
    name: 'Disney - Disney+ Ad Revenues',
    collectionId: '640613733a604f112860847b',
    templateId: '63586034ca376f0a34e96a95',
  },
  {
    name: 'Disney - Hulu Ad Revenues',
    collectionId: '640613733a604f112860847b',
    templateId: '5aa9474aceca0e0e708c9fec',
  },
  {
    name: 'Disney+ - Disney+ Ad Revenues',
    collectionId: '5fd8be125e10fc0ff04a1b78',
    templateId: '63586034ca376f0a34e96a95',
  },
  { name: 'Facebook Ad Revenues', collectionId: '5aa1832560a1570f8cae1aa1', templateId: '6243730105bb2b076c306dd0' },
  { name: 'Google Ad Revenues', collectionId: '591373e4aeb8830e3829e400', templateId: '59e7c0f5bfce890eb411ec57' },
  { name: 'Hulu Ad Revenues', collectionId: '5aab6284a2835e0fdc2e72ed', templateId: '5aa9474aceca0e0e708c9fec' },
  {
    name: 'iHeartMedia - iHeartMedia Digital Ad Revenues',
    collectionId: '6054df9cd8d58b0edcaed4a0',
    templateId: '6054d768d6480d089c603b44',
  },
  { name: 'Instagram Ad Revenues', collectionId: '584b26021403070290f93a15', templateId: '5851918a0626310a2c186a23' },
  {
    name: 'In-Store Retail Media Ad Spending',
    collectionId: '618c389c200dbd0e3cddcb6f',
    templateId: '642cc81199da3116acff94f0',
  },
  { name: 'LinkedIn Ad Revenues', collectionId: '59137e08aeb8830e3829e412', templateId: '5851918a0626310a2c186a33' },
  { name: 'Meta Ad Revenues', collectionId: '584b26021403070290f93a19', templateId: '5851918a0626310a2c1869fb' },
  { name: 'Microsoft Ad Revenues', collectionId: '59147943aeb8830e3829e644', templateId: '591212b7aeb8830e3829e2df' },
  { name: 'Mobile Game Ad Revenues', collectionId: '5c76d87669f80503c46bb0e2', templateId: '5c76cdc069f80503c46bb0d7' },
  { name: 'Netflix Ad Revenues', collectionId: '5919f981aeb8830e3829eb77', templateId: '63597ffcd3496f0694309730' },
  {
    name: 'Nonmobile Game Ad Revenues',
    collectionId: '5c76d87669f80503c46bb0e2',
    templateId: '654405d1d05bf2db4beccd03',
  },
  {
    name: 'Pandora - Pandora Ad Revenues',
    collectionId: '588131525c0a5b0a684aaeb8',
    templateId: '5f8e3d078b63e9055c1aa484',
  },
  { name: 'Paramount+ Ad Revenues', collectionId: '6359b33fb169d30bac0c9720', templateId: '63597dc8d3496f0694309729' },
  { name: 'Peacock Ad Revenues', collectionId: '620c5c8c77313b0690ad2aac', templateId: '63598857d3496f069430973a' },
  { name: 'Podcast Ad Spending', collectionId: '5e5577c61beeb905989e3975', templateId: '5f159d93fd62d60b00d4189a' },
  {
    name: 'Print Magazine Ad Spending',
    collectionId: '5f6d0adcce2869163862ccd4',
    templateId: '61844771b215ff0ffc267a21',
  },
  {
    name: 'Print Newspaper Ad Spending',
    collectionId: '5f6d0b1ece2869163862ccd6',
    templateId: '618447f2200dbd05f4a73ef3',
  },
  {
    name: 'Retail Media Ad Spending',
    collectionId: '618c389c200dbd0e3cddcb6f',
    templateId: '61708362200dbd0afca524c7',
  },
  { name: 'Snapchat Ad Revenues', collectionId: '584b26021403070290f93a1b', templateId: '59136c37aeb8830e3829e3fa' },
  {
    name: 'Social Network Ad Spending',
    collectionId: '584b26021403070290f93a5b',
    templateId: '5851918b0626310a2c186b11',
  },
  {
    name: 'Spotify - Spotify Ad Revenues',
    collectionId: '5c65f5968920aa03ec25746a',
    templateId: '5c65f47b8920aa03ec257466',
  },
  { name: 'TikTok Ad Revenues', collectionId: '5e4c6b5ec56a401090de843e', templateId: '6243d8956eb6f904249bd064' },
  { name: 'Total Game Ad Revenues', collectionId: '5c76d87669f80503c46bb0e2', templateId: '654404f0d05bf2db4beccd01' },
  {
    name: 'Traditional Media Ad Spending - Print Magazine Ad Spending',
    collectionId: '5c66d06c8920aa05b8b0e35a',
    templateId: '61844771b215ff0ffc267a21',
  },
  {
    name: 'Traditional Media Ad Spending - Print Newspaper Ad Spending',
    collectionId: '5c66d06c8920aa05b8b0e35a',
    templateId: '618447f2200dbd05f4a73ef3',
  },
  {
    name: 'Traditional Media Ad Spending - Radio Ad Spending',
    collectionId: '5c66d06c8920aa05b8b0e35a',
    templateId: '5851918a0626310a2c186ab5',
  },
  {
    name: 'Traditional Out-of-Home Ad Spending',
    collectionId: '5c66d06c8920aa05b8b0e35a',
    templateId: '618448adb215ff0ffc267a24',
  },
  {
    name: 'X (formerly Twitter) Ad Revenues',
    collectionId: '584b26021403070290f93a16',
    templateId: '5851918b0626310a2c186b61',
  },
  { name: 'YouTube Ad Revenues', collectionId: '59148665aeb8830e3829e6a0', templateId: '591244dbaeb8830e3829e3cd' },
];
