import { handleActions } from 'redux-actions';
import * as AT from '../constants/action-types';

const initialState = {
  results: [],
};

const brandingAssetsReducer = handleActions(
  {
    [AT.SET_BRANDING_SIDE_RAIL.SUCCESS]: (state, { payload }) => {
      const newState = {
        results: payload,
      };

      return newState;
    },
    [AT.SET_BRANDING_SIDE_RAIL.FAILURE]: (state, { payload }) => {
      const newState = {
        results: [],
      };

      return newState;
    },
  },
  initialState
);

export default brandingAssetsReducer;
