import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const FilterButton = (props) => {
  const { name, callback, ghost } = props;

  return (
    <Filter onClick={callback} ghost={ghost}>
      <Icon className="fa" iconColor={props.iconColor} />
      {name}
    </Filter>
  );
};

FilterButton.propTypes = {
  name: PropTypes.string.isRequired,
  callback: PropTypes.func.isRequired,
  iconColor: PropTypes.string,
  ghost: PropTypes.any,
};

const Filter = styled.button`
  display: inline-block;
  background-color: #f5f5f5;
  font-size: 12px;
  border: none;
  font-family: 'Lab Grotesque', sans-serif;
  line-height: 1.4;
  font-weight: 700;
  font-style: normal;
  text-rendering: optimizeLegibility;

  ${({ ghost }) =>
    ghost &&
    `
    padding: 2px 8px;
    border: 1px solid black;
    border-radius: 5px;
  `}

  &:hover {
    i {
      color: red;
    }
  }
`;

const Icon = styled.i`
  color: ${(props) => (props.iconColor ? props.iconColor : 'black')};
  border: none;
  visibility: visible;
  cursor: pointer;
  margin-right: 6px;

  &:before {
    content: '\f00d';
    font-weight: 700;
    font-family: Font Awesome 5 Free;
    font-size: 90%;
  }
`;

export default FilterButton;
