import { handleActions } from 'redux-actions';

import { sortKeysAlphabetic, mapWithKey } from '../utils/general';
import { getInitialFacetState, filterVisibleFacetState } from '../utils/facets';
import config from '../config';
import * as AT from '../constants/action-types';

/* initialState should look like this:
const initialState = {
  'geographies.lvl0': {},
  'type': {},
  'topics.lvl0': {},
  'industries.lvl0': {},
  'demographics.lvl0': {},
  'keywords': {},
  'published_date': {}
};
*/
const initialState = getInitialFacetState(config.AUDIENCE);

const facetsReducer = handleActions(
  {
    [AT.SEARCH.SUCCESS]: (state, { payload }) => {
      let newFacets = initialState;

      mapWithKey((facetValue, facetKey) => {
        newFacets = {
          ...newFacets,
          [facetKey]: sortKeysAlphabetic(facetValue),
        };
      }, filterVisibleFacetState(payload.facets));

      return newFacets;
    },
  },
  initialState
);

export default facetsReducer;
