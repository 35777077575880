import * as AT from '../constants/action-types';

export const selectFacetFilter = (selectedFacetFilter) => ({
  type: AT.SELECT_FACET_FILTER,
  payload: {
    selectedFacetFilter,
  },
});

export const selectMultipleFacetFilters = (selectedFacetFilters) => ({
  type: AT.SELECT_MULTIPLE_FACET_FILTERS,
  payload: {
    selectedFacetFilters,
  },
});

export const unselectFacetFiltersOfType = (type) => ({
  type: AT.UNSELECT_MULTIPLE_FACETS_OF_TYPE,
  payload: type,
});

export const unselectFacetFilter = (facetFilterToRemove) => ({
  type: AT.UNSELECT_FACET_FILTER,
  payload: {
    facetFilterToRemove,
  },
});

export const reSelectFacetFilter = ({ facetToSelect, facetToUnselect }) => ({
  type: AT.RESELECT_FACET_FILTER,
  payload: {
    facetToSelect,
    facetToUnselect,
  },
});

export const clearAllFilters = () => ({
  type: AT.CLEAR_ALL_FILTERS,
});
