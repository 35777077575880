import React from 'react';
import { PropTypes } from 'prop-types';
import {
  AdFluid,
  AD_SLOT_TYPE__MIDDLE_FLUID1,
  AD_SLOT_TYPE__MIDDLE_FLUID2,
} from '@insiderintelligence/googleadmanager';
import { IdleUntilUrgent } from '../../../utils/idleUntilUrgent';

import { visibleResultTypes } from '../../../utils/result-components';

const resultTypes = visibleResultTypes();

const ConditionalWrapper = ({ condition, wrapper, children }) => (condition ? wrapper(children) : children);

const Result = ({ result }) => {
  if (!resultTypes[result.type]) {
    return null;
  }

  const Component = resultTypes[result.type];

  return (
    <ConditionalWrapper
      condition={result.type === 'Ad' || result.type === 'TextAd'}
      wrapper={(children) => (
        <li>
          {result.type === 'TextAd' ? (
            <div>
              <AdFluid adSlotType={AD_SLOT_TYPE__MIDDLE_FLUID1} />
            </div>
          ) : (
            <div>
              <AdFluid adSlotType={AD_SLOT_TYPE__MIDDLE_FLUID2} />
            </div>
          )}
          {/* {children} */}
        </li>
      )}
    >
      <React.Fragment>
        <IdleUntilUrgent htmlElement="div">
          <Component result={result} />
        </IdleUntilUrgent>
      </React.Fragment>
    </ConditionalWrapper>
  );
};

// TODO: unused, but copied from source PR.
// const AdWrapper = styled.div`
//   border-bottom: 1px solid rgba(0, 0, 0, 0.13);
//   padding: 19px 0px 13px;
//   position: relative;

//   @media (min-width: 1280px) {
//     width: 825px;
//     height: ${props => props.adType === "Ad" ? "207px" : "auto" };
//   }

//   img {
//     aspect-ratio: 4 / 1;
//   }
// `;

Result.propTypes = {
  result: PropTypes.object.isRequired,
};

export default Result;
