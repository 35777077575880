import * as AT from '../constants/action-types';

export const ACTION_TO_INITIATE_SEARCH = [
  AT.SET_SEARCH_QUERY,
  AT.SUBMIT_SEARCH_QUERY,
  AT.SELECT_FACET_FILTER,
  AT.SELECT_MULTIPLE_FACET_FILTERS,
  AT.UNSELECT_MULTIPLE_FACETS_OF_TYPE,
  AT.SELECT_NUMERIC_FILTER,
  AT.UNSELECT_FACET_FILTER,
  AT.UNSELECT_NUMERIC_FILTER,
  AT.RESELECT_FACET_FILTER,
  AT.SET_SORTING_OPTION,
  AT.SET_INITIAL_SEARCH_QUERY_FROM_URL_PARAMS,
  AT.SET_PAGE,
  AT.CLEAR_ALL_FILTERS,
];
