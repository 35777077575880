import React from 'react';
import PropTypes from 'prop-types';
import { RadioButtonWrapper } from './RadioButton.styled';

export const CHECKED = 1;
export const UNCHECKED = 2;

// interface ICheckbox {
// value: number,
// callback?: any,
// readOnly: boolean,
// className?: string,
// disabled?: boolean
// }

// const defaultProps: ICheckbox = {
//   value: 2,
//   readOnly: true,
//   disabled: false
// }

const RadioButton = (props) => {
  const { value = 2, groupName, readOnly = true, disabled = false, callback, className } = props;

  // const radioRef = useRef(null);

  // useEffect(() => {
  //   if (radioRef && radioRef.current) {
  //     radioRef.current.checked = value == CHECKED
  //   }
  // }, [value])

  const handleChange = () => {
    if (callback && value === UNCHECKED) {
      callback();
    }
  };

  return (
    <RadioButtonWrapper
      type="radio"
      disabled={disabled}
      name={groupName}
      readOnly={readOnly}
      onChange={() => handleChange()}
      defaultChecked={value === CHECKED}
      checked={value === CHECKED}
      style={{ cursor: 'pointer' }}
      className={className}
    />
  );
};

RadioButton.propTypes = {
  value: PropTypes.number.isRequired,
  groupName: PropTypes.string.isRequired,
  callback: PropTypes.func,
  readOnly: PropTypes.bool.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
};

export default RadioButton;
