import { handleActions } from 'redux-actions';

import * as AT from '../constants/action-types';

const initialState = {
  adUnits: {},
  topics: [],
  loading: false,
  loaded: false,
};

const adsReducer = handleActions(
  {
    // eslint-disable-next-line no-empty-pattern
    [AT.LOAD_ADS_FAIL]: (state, {}) => ({
      adUnits: [],
      topics: state.topics,
      loading: false,
      loaded: true,
    }),
    [AT.LOAD_ADS_SUCCESS]: (state, { result }) => ({
      adUnits: result.ads,
      topics: state.topics,
      loading: false,
      loaded: true,
    }),
  },
  initialState
);

export default adsReducer;
