import React from 'react';
import PropTypes from 'prop-types';

import { ListWrapper, ScrollbarWrapper } from './List.styled';

// export interface IList extends BaseComponent {
// orientation?: 'vertical' | 'horizontal';
// divider?: any;
// customScrollbar?: any;
// customScrollbarProps?: any;
// customScrollbarPadding?: number;
// spaceBetween?: number;
// listStyle?: string;
// listRef?: any;
// useDefaultScrollbar?: boolean;
// isShaded?: boolean;
// }

const List = (props) => {
  const {
    orientation = 'vertical',
    divider,
    customScrollbarPadding = 16,
    spaceBetween = 8,
    customScrollbar,
    customScrollbarProps /* ALWAYS SUPPLY A MAXHEIGHT STYLE OBJECT */,
    listStyle = 'none',
    listRef,
    useDefaultScrollbar = false,
    isShaded = false,
    className,
    children,
  } = props;

  const numberOfItems = React.Children.count(children);

  const items = React.Children.map(children, (e, index) => {
    const item = [<li key={index}>{e}</li>];
    if (divider && index !== numberOfItems - 1)
      item.push(
        orientation === 'vertical'
          ? React.cloneElement(divider, {
              marginY: spaceBetween / 2,
            })
          : React.cloneElement(divider, {
              marginX: spaceBetween / 2,
            })
      );
    return item;
  });

  const list = (
    <ListWrapper
      listStyle={listStyle}
      orientation={orientation}
      customScrollbar={useDefaultScrollbar && !customScrollbar ? null : customScrollbar}
      customScrollbarPadding={customScrollbarPadding}
      spaceBetween={spaceBetween}
      isShaded={isShaded}
      isDivided={divider}
      className={className}
    >
      {items}
    </ListWrapper>
  );

  const Scrollbar = useDefaultScrollbar && !customScrollbar ? null : customScrollbar;

  return Scrollbar ? (
    <ScrollbarWrapper>{React.cloneElement(<Scrollbar ref={listRef} />, customScrollbarProps, list)}</ScrollbarWrapper>
  ) : (
    list
  );
};

List.propTypes = {
  orientation: PropTypes.string,
  divider: PropTypes.any,
  customScrollbar: PropTypes.any,
  customScrollbarProps: PropTypes.any,
  customScrollbarPadding: PropTypes.number,
  spaceBetween: PropTypes.number,
  listStyle: PropTypes.string,
  listRef: PropTypes.any,
  useDefaultScrollbar: PropTypes.bool,
  isShaded: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

export default List;
