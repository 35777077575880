import styled from 'styled-components';

const ResultContainer = styled.li`
  border-bottom: 1px solid ${({ theme }) => theme.resultBorder};
  padding: 15px 0;
  position: relative;

  &:last-child {
    border-bottom: none;
  }

  &:first-child {
    padding-top: 0px;
  }
`;

export default ResultContainer;
