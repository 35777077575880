import { PUBLIC_AUDIENCE } from './constants/config';

// Retrieve the value passed from client
const getClientValue = (varName) => {
  const value = window[`__${varName}`];

  if (value !== 'undefined') {
    delete window[`__${varName}`];
  }

  return value === 'undefined' ? null : value;
};

const adTagSpecs = [
  { Tag: 'Top1', Id: '377294', Size: '1170x117' },
  { Tag: 'Bottom1', Id: '377298', Size: '1170x293' },
  { Tag: 'Middle1', Id: '377297', Size: '1170x117' },
  { Tag: 'Right1', Id: '377295', Size: '560x280' },
  { Tag: 'Left1', Id: '377296', Size: '560x280' },
  { Tag: 'TxtLink1', Id: '377299', Size: '826x140' },
];

const config = {
  ENABLE_PREMIUM_FEATURES:
    typeof window !== 'undefined' ? getClientValue('ENABLE_PREMIUM_FEATURES') : process.env.ENABLE_PREMIUM_FEATURES,
  ADS_ENABLED: false,
  DEFAULT_DATE_FILTER_ENABLED: true,
  POD_REDIRECT_ENABLED: true,
  RELATED_QUERIES_ENABLED: true,
  AUDIENCE: typeof window !== 'undefined' ? getClientValue('AUDIENCE') : process.env.AUDIENCE,
  ANALYTICS_SERVICE_URL:
    typeof window !== 'undefined' ? getClientValue('ANALYTICS_SERVICE_URL') : process.env.ANALYTICS_SERVICE_URL,
  CURRENT_ENVIRONMENT:
    typeof window !== 'undefined' ? getClientValue('CURRENT_ENVIRONMENT') : process.env.CURRENT_ENVIRONMENT,
  NODE_ENV: typeof window !== 'undefined' ? getClientValue('NODE_ENV') : process.env.NODE_ENV,
  SEO_BASE_URL: typeof window !== 'undefined' ? getClientValue('SEO_BASE_URL') : process.env.SEO_BASE_URL,
  MAX_MONTHS: typeof window !== 'undefined' ? getClientValue('MAX_MONTHS') : process.env.MAX_MONTHS,
  QUERY_VERSION: typeof window !== 'undefined' ? getClientValue('QUERY_VERSION') : process.env.QUERY_VERSION,
  ELASTICSEARCH_URI_READ:
    typeof window !== 'undefined' ? getClientValue('ELASTICSEARCH_URI_READ') : process.env.ELASTICSEARCH_URI_READ,
  PUBLIC_PATH: typeof window !== 'undefined' ? getClientValue('PUBLIC_PATH') : process.env.PUBLIC_PATH,
  COMMON_TYPEAHEAD_SERVICE_URL:
    typeof window !== 'undefined'
      ? getClientValue('COMMON_TYPEAHEAD_SERVICE_URL')
      : process.env.COMMON_TYPEAHEAD_SERVICE_URL,
  SEARCH_APP_NAME: typeof window !== 'undefined' ? getClientValue('SEARCH_APP_NAME') : process.env.SEARCH_APP_NAME,
  SEARCH_INDEX_NAME:
    typeof window !== 'undefined' ? getClientValue('SEARCH_INDEX_NAME') : process.env.SEARCH_INDEX_NAME,
  SEARCH_MAJOR_VERSION:
    typeof window !== 'undefined' ? getClientValue('SEARCH_MAJOR_VERSION') : process.env.SEARCH_MAJOR_VERSION,
  SEARCH_TEMPLATE_NAME:
    typeof window !== 'undefined' ? getClientValue('SEARCH_TEMPLATE_NAME') : process.env.SEARCH_TEMPLATE_NAME,
  SEARCH_BASE_URL: typeof window !== 'undefined' ? getClientValue('SEARCH_BASE_URL') : process.env.SEARCH_BASE_URL,
  AD_NETWORK_ID: typeof window !== 'undefined' ? getClientValue('AD_NETWORK_ID') : process.env.AD_NETWORK_ID,
  AD_SERVER_URL: typeof window !== 'undefined' ? getClientValue('AD_SERVER_URL') : process.env.AD_SERVER_URL,
  AD_TAG_SPECS: adTagSpecs,
  RELATED_QUERIES_URL:
    typeof window !== 'undefined' ? getClientValue('RELATED_QUERIES_URL') : process.env.RELATED_QUERIES_URL,
  COMMON_ARTICLES_SERVICE_URL:
    typeof window !== 'undefined'
      ? getClientValue('COMMON_ARTICLES_SERVICE_URL')
      : process.env.COMMON_ARTICLES_SERVICE_URL,
  COMMON_OSANO_SCRIPT_URL:
    typeof window !== 'undefined' ? getClientValue('COMMON_OSANO_SCRIPT_URL') : process.env.COMMON_OSANO_SCRIPT_URL,

  COMMON_OSF_SCRIPT_URL:
    typeof window !== 'undefined' ? getClientValue('COMMON_OSF_SCRIPT_URL') : process.env.COMMON_OSF_SCRIPT_URL,

  COMMON_GA_API_SECRET:
    typeof window !== 'undefined' ? getClientValue('COMMON_GA_API_SECRET') : process.env.COMMON_GA_API_SECRET,
  COMMON_GA_MEASUREMENT_ID:
    typeof window !== 'undefined' ? getClientValue('COMMON_GA_MEASUREMENT_ID') : process.env.COMMON_GA_MEASUREMENT_ID,
  COMMON_GAM_GPT_SCRIPT_URL:
    typeof window !== 'undefined' ? getClientValue('COMMON_GAM_GPT_SCRIPT_URL') : process.env.COMMON_GAM_GPT_SCRIPT_URL,
  COMMON_GAM_NETWORK_ID:
    typeof window !== 'undefined' ? getClientValue('COMMON_GAM_NETWORK_ID') : process.env.COMMON_GAM_NETWORK_ID,
  COMMON_GAM_PARENT_AD_UNIT_ID:
    typeof window !== 'undefined'
      ? getClientValue('COMMON_GAM_PARENT_AD_UNIT_ID')
      : process.env.COMMON_GAM_PARENT_AD_UNIT_ID,
  COMMON_GAM_TEST_AD_CONTAINER_ID:
    typeof window !== 'undefined'
      ? getClientValue('COMMON_GAM_TEST_AD_CONTAINER_ID')
      : process.env.COMMON_GAM_TEST_AD_CONTAINER_ID,
  GAM_CURRENT_MICROSERVICE_S1_ID:
    typeof window !== 'undefined'
      ? getClientValue('GAM_CURRENT_MICROSERVICE_S1_ID')
      : process.env.GAM_CURRENT_MICROSERVICE_S1_ID,
  COMMON_USER_EMAILDOMAIN_COOKIE:
    typeof window !== 'undefined'
      ? getClientValue('COMMON_USER_EMAILDOMAIN_COOKIE')
      : process.env.COMMON_USER_EMAILDOMAIN_COOKIE,
  SUBSCRIPTIONS_DOES_MY_COMPANY_SUBSCRIBE_PLUGIN_URL:
    typeof window !== 'undefined'
      ? getClientValue('SUBSCRIPTIONS_DOES_MY_COMPANY_SUBSCRIBE_PLUGIN_URL')
      : process.env.SUBSCRIPTIONS_DOES_MY_COMPANY_SUBSCRIBE_PLUGIN_URL,
  COMMON_SEARCH_SERVICE_URL:
    typeof window !== 'undefined' ? getClientValue('COMMON_SEARCH_SERVICE_URL') : process.env.COMMON_SEARCH_SERVICE_URL,
  COMMON_NOTEWORTHY_SERVICE_URL:
    typeof window !== 'undefined'
      ? getClientValue('COMMON_NOTEWORTHY_SERVICE_URL')
      : process.env.COMMON_NOTEWORTHY_SERVICE_URL,
  COMMON_NOTEWORTHY_API_URL:
    typeof window !== 'undefined' ? getClientValue('COMMON_NOTEWORTHY_API_URL') : process.env.COMMON_NOTEWORTHY_API_URL,
  POD_REDIRECTION_SERVICE_URL:
    typeof window !== 'undefined'
      ? getClientValue('POD_REDIRECTION_SERVICE_URL')
      : process.env.POD_REDIRECTION_SERVICE_URL,
  COMMON_SUBSCRIPTIONS_SERVICE_URL:
    typeof window !== 'undefined'
      ? getClientValue('COMMON_SUBSCRIPTIONS_SERVICE_URL')
      : process.env.COMMON_SUBSCRIPTIONS_SERVICE_URL,
  COMMON_CONTENT_SERVICE_URL: typeof window !== 'undefined' ? getClientValue('COMMON_CONTENT_SERVICE_URL') : process.env.COMMON_CONTENT_SERVICE_URL,
  COMMON_FORECASTS_SERVICE_URL:
    typeof window !== 'undefined'
      ? getClientValue('COMMON_FORECASTS_SERVICE_URL')
      : process.env.COMMON_FORECASTS_SERVICE_URL,
  COMMON_BASE_URL: typeof window !== 'undefined' ? getClientValue('COMMON_BASE_URL') : process.env.COMMON_BASE_URL,
  COMMON_RESEARCH_TOPICS_SERVICE_URL:
    typeof window !== 'undefined'
      ? getClientValue('COMMON_RESEARCH_TOPICS_SERVICE_URL')
      : process.env.COMMON_RESEARCH_TOPICS_SERVICE_URL,
  COMMON_QUERYSTRING_KEEPER_URL:
    typeof window !== 'undefined'
      ? getClientValue('COMMON_QUERYSTRING_KEEPER_URL')
      : process.env.COMMON_QUERYSTRING_KEEPER_URL,

  COMMON_FONTS_CSS_URL:
    typeof window !== 'undefined' ? getClientValue('COMMON_FONTS_CSS_URL') : process.env.COMMON_FONTS_CSS_URL,
  COMMON_DEFAULT_SOCIAL_THUMBNAIL_URL:
    typeof window !== 'undefined'
      ? getClientValue('COMMON_DEFAULT_SOCIAL_THUMBNAIL_URL')
      : process.env.COMMON_DEFAULT_SOCIAL_THUMBNAIL_URL,

  DD_ENABLED: typeof window !== 'undefined' ? getClientValue('DD_ENABLED') : process.env.DD_ENABLED,
  DD_ALLOWED_TRACING_ORIGIN:
    typeof window !== 'undefined' ? getClientValue('DD_ALLOWED_TRACING_ORIGIN') : process.env.DD_ALLOWED_TRACING_ORIGIN,
  DD_APPLICATION_ID:
    typeof window !== 'undefined' ? getClientValue('DD_APPLICATION_ID') : process.env.DD_APPLICATION_ID,
  DD_CLIENT_TOKEN: typeof window !== 'undefined' ? getClientValue('DD_CLIENT_TOKEN') : process.env.DD_CLIENT_TOKEN,
  DD_BROWSER_SAMPLE_RATE:
    typeof window !== 'undefined' ? getClientValue('DD_BROWSER_SAMPLE_RATE') : process.env.DD_BROWSER_SAMPLE_RATE,
  DD_TRACE_SAMPLE_RATE:
    typeof window !== 'undefined' ? getClientValue('DD_TRACE_SAMPLE_RATE') : process.env.DD_TRACE_SAMPLE_RATE,
  DD_SERVICE: typeof window !== 'undefined' ? getClientValue('DD_SERVICE') : process.env.DD_SERVICE,
  DD_ENV: typeof window !== 'undefined' ? getClientValue('DD_ENV') : process.env.DD_ENV,
  DD_VERSION: typeof window !== 'undefined' ? getClientValue('DD_VERSION') : process.env.DD_VERSION,
};
switch (config.AUDIENCE) {
  case PUBLIC_AUDIENCE:
    config.DEFAULT_DATE_FILTER_ENABLED = true;
    config.ADS_ENABLED = true;
    config.RELATED_QUERIES_ENABLED = true;
    break;
  default:
    break;
}

export default config;
