import { getFacetValueName } from '../utils/facets';
import { someWithKey } from '../utils/general';
import { FILTERS } from '../constants/facets';
import { isLoggedIn, isOnBrowser } from '../utils/general';
import { fetchAds } from '../middlewares/ad';
import config from '../config';

export const computeAdTopics = (dispatch, query, selectedFacetsFilters) => {
  let topics = [];

  if (query !== '') {
    topics.push(query);
  }

  if (selectedFacetsFilters !== undefined) {
    let topicFilterName;

    let hasTopicFilter = someWithKey((filterValue, filterName) => {
      const isIncluding = filterName.includes(FILTERS.TOPICS);

      if (isIncluding) {
        topicFilterName = filterName;
      }

      return isIncluding;
    }, selectedFacetsFilters);

    if (hasTopicFilter) {
      topics.push(getFacetValueName(selectedFacetsFilters[topicFilterName]));
    }

    if (isOnBrowser()) {
      if (config.ADS_ENABLED && !isLoggedIn()) {
        let adUnits = ['Top1', 'Middle1', 'TxtLink1', 'Bottom1'];
        dispatch(fetchAds(query, selectedFacetsFilters, adUnits));
      }
    }
  }
};
