import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { PREVIEW_ASSET, VIEW_ASSET } from '../../../../constants/action-types';
import { getWindow } from '../../../../utils/general';
import { trackEvent as Track, delayNavigationForAnalytics } from '../../../../utils/analytics';
import { premiumForecasts } from 'constants/premium';
import PremiumTag from 'components/reusable-components/molecules/PremiumTag';
import config from '../../../../config';

class Title extends React.Component {
  state = {
    tooltipOpen: false,
  };

  getLink = (result) => (result.type === 'Numbers' ? `${result.url}/${result.default_template_id}` : `${result.url}`);

  getID = () => this.props.result.type + this.props.result.objectID;

  trackPreview = (result, query) => {
    this.props.assetPreviewed(result.objectID);
    Track(`Result-Preview-Hover-${result.objectID}--QUERY:${query}`);
    // if (!isNaN(result.objectID)){
    //     History({ assetId: result.id, oldAssetId: result.oldAssetId, assetType: result.type, assetTitle: result.title });
    // }
  };

  onMouseDownHandler = (e) => {
    // ignore right clicks, handled by onContextMenu
    if (e.which === 3 || e.button === 2) return;

    this.props.assetViewed(this.props.result.objectID);
    delayNavigationForAnalytics(e);
  };

  onContextMenuHandler = () => {
    this.props.assetViewed(this.props.result.objectID);
  };

  onKeyDownHandler = (e) => {
    if (e.keyCode === 13) {
      this.props.assetViewed(this.props.result.objectID);
      delayNavigationForAnalytics(e);
    }
  };

  mouseEnterHandler = () => {
    clearTimeout(this.timeout);
    if (getWindow().innerWidth > 767) {
      this.timeout = setTimeout(() => {
        this.openTooltip();
      }, 250);
    }
  };

  mouseLeaveHandler = () => {
    clearTimeout(this.timeout);
    if (this.state.tooltipOpen) {
      this.timeout = setTimeout(() => {
        this.closeTooltip();
      }, 250);
    }
  };

  openTooltip = () => {
    if (!this.props.result.thumbnail) {
      return;
    }
    if (!this.previewTimeout) {
      this.previewTimeout = window.setTimeout(() => this.trackPreview(this.props.result, this.props.searchQuery), 3000);
      this.setState({ tooltipOpen: true });
    } else {
      this.setState({ tooltipOpen: true });
    }
  };

  closeTooltip = () => {
    if (this.previewTimeout) {
      clearTimeout(this.previewTimeout);
      this.previewTimeout = false;
    }
    this.setState({ tooltipOpen: false });
  };

  componentDidMount() {
    window.addEventListener('scroll', this.closeTooltip);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.closeTooltip);
  }
  render() {
    const formatTitle = (title) => {
      const containsBlurb = title.indexOf(': ') > -1;
      if (containsBlurb) {
        return (
          <TitleText>
            {title.split(': ')[0]}: <TitleBlurb>{title.split(': ')[1]}</TitleBlurb>
          </TitleText>
        );
      }
      return <TitleText>{title}</TitleText>;
    };

    const shouldDisplayPremiumTag = (id) => {
      if (!id || config.ENABLE_PREMIUM_FEATURES.toString() === 'false') {
        return false;
      }
      return premiumForecasts.some((f) => f.templateId === id);
    };

    return (
      <Container>
        <TitleWrapper
          onMouseOver={this.mouseEnterHandler}
          onOuterAction={this.mouseLeaveHandler}
          onMouseOut={this.mouseLeaveHandler}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <a
              href={this.getLink(this.props.result)}
              id={this.getID()}
              onMouseDown={this.onMouseDownHandler}
              onContextMenu={this.onContextMenuHandler}
              onKeyDown={this.onKeyDownHandler}
              data-analytics={`Result-Link-${this.getID()}--QUERY:${this.props.searchQuery}`}
            >
              {formatTitle(this.props.title)}
            </a>
            {shouldDisplayPremiumTag(this.props.result.default_template_id) ? <PremiumTag /> : null}
          </div>
        </TitleWrapper>
      </Container>
    );
  }
}

const Container = styled.div`
  margin-right: 0;
  position: relative;
`;

const TitleWrapper = styled.h2`
  width: 100%;
  line-height: 22px;
  padding-right: 10px;
  padding-left: 0;
  margin-bottom: 10px;
  display: flex;

  font-family: 'Lab Grotesque', sans-serif;
  line-height: 1.1;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;

  a {
    color: ${({ theme }) => theme.title};
  }

  a:hover {
    color: ${({ theme }) => theme.titleHover};
    text-decoration: underline;
  }

  a:visited {
    color: ${({ theme }) => theme.titleVisited};

    span {
      color: ${({ theme }) => theme.titleVisited};
    }
  }
`;

const TitleText = styled.span`
  font-size: 21px;
  font-weight: 900;
  line-height: 26px;
`;

const TitleBlurb = styled.span`
  color: ${({ theme }) => theme.blurb};
  font-size: 19px;
  font-weight: 400;
`;

Title.propTypes = {
  result: PropTypes.object.isRequired,
  searchQuery: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  assetPreviewed: PropTypes.func.isRequired,
  assetViewed: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  searchQuery: state.currentSearchQuery,
});

const mapDispatchToProps = (dispatch) => {
  return {
    assetPreviewed: (assetId) => dispatch({ type: PREVIEW_ASSET, payload: assetId }),
    assetViewed: (assetId) => dispatch({ type: VIEW_ASSET, payload: assetId }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Title);
