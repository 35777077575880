import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import ResultContainer from '../../../../components/search-results/result/result-types/result-container';
import { trackEvent } from '../../../../utils/analytics';

class TextAd extends React.Component {
  handleOnClick = () => {
    trackEvent(`Ad-TxtLink1`);
  };

  render() {
    return (
      <ResultContainer onClick={this.handleOnClick}>
        <Container dangerouslySetInnerHTML={{ __html: this.props.ad }} />
      </ResultContainer>
    );
  }
}

const Container = styled.div`
  width: 100%;
  img {
    max-width: 100%;
    height: auto;
  }

  .txt-ad {
    box-sizing: border-box;
    padding: 10px 20px;
    background: #f5f5f5;
    background: -moz-linear-gradient(top, #f5f5f5 0%, #e2e2e2 100%);
    background: -webkit-linear-gradient(top, #f5f5f5 0%, #e2e2e2 100%);
    background: linear-gradient(to bottom, #f5f5f5 0%, #e2e2e2 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f5f5f5', endColorstr='#e2e2e2',GradientType=0 );

    .mini-txt {
      > span {
        font-size: 14px;
        color: #777777;
      }
    }

    .ad-heading {
      color: #333 !important;
      line-height: 24px !important;
      margin: 0 0 5px 0;

      a {
        color: #333;
        font-size: 20px;
      }
    }

    .ad-copy {
      font-size: 16px;
      line-height: 20px;
      color: #333;
      margin: 0 0 10px 0;
    }

    .ad-cta {
      font-size: 18px !important;
      color: #1387ba !important;
    }
  }
`;

TextAd.propTypes = {
  ad: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    ad: state.ads.adUnits['TxtLink1'],
  };
};

export default connect(mapStateToProps, null)(TextAd);
