import { createAsyncAction } from '../utils/actions';

export const API_REQUEST = 'API_REQUEST';

export const SEARCH = createAsyncAction('SEARCH');
export const SELECT_FACET_FILTER = 'SELECT_FACET_FILTER';
export const SELECT_MULTIPLE_FACET_FILTERS = 'SELECT_MULTIPLE_FACET_FILTERS';
export const UNSELECT_MULTIPLE_FACETS_OF_TYPE = 'UNSELECT_MULTIPLE_FACETS_OF_TYPE';
export const UNSELECT_FACET_FILTER = 'UNSELECT_FACET_FILTER';
export const RESELECT_FACET_FILTER = 'RESELECT_FACET_FILTER';
export const CLEAR_ALL_FILTERS = 'CLEAR_ALL_FILTERS';

export const SELECT_NUMERIC_FILTER = 'SELECT_NUMERIC_FILTER';
export const UNSELECT_NUMERIC_FILTER = 'UNSELECT_NUMERIC_FILTER';

export const SET_SEARCH_QUERY = 'SET_SEARCH_QUERY';
export const NEW_SEARCH_QUERY = 'NEW_SEARCH_QUERY';
export const SUBMIT_SEARCH_QUERY = 'SUBMIT_SEARCH_QUERY';

export const SET_SORTING_OPTION = 'SET_SORTING_OPTION';

export const SET_INITIAL_SEARCH_QUERY_FROM_URL_PARAMS = 'SET_INITIAL_SEARCH_QUERY_FROM_URL_PARAMS';

export const SET_PAGE = 'SET_PAGE';

export const HIDE_ALERT_MESSAGE = 'HIDE_ALERT_MESSAGE';

export const LOAD_ADS = 'LOAD_ADS';
export const LOAD_ADS_SUCCESS = 'LOAD_ADS_SUCCESS';
export const LOAD_ADS_FAIL = 'LOAD_ADS_FAIL';

export const PREVIEW_ASSET = 'PREVIEW_ASSET';
export const VIEW_ASSET = 'VIEW_ASSET';

export const SET_SEARCH_ID = 'SET_SEARCH_ID';

export const PAGE_LOADED = 'PAGE_LOADED';

export const SET_SOURCE = 'SET_SOURCE';

export const RELATED_QUERIES = createAsyncAction('RELATED_QUERIES');
export const SET_BRANDING_SIDE_RAIL = createAsyncAction('SET_BRANDING_SIDE_RAIL');
