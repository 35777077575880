import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import Tab from '../../reusable-components/molecules/Tab';

import { setSortingOption as setSorting } from '../../../actions/sorting-option';

import { SORTING_OPTIONS } from '../../../constants/sorting-options';
import { AppContext } from '../../AppContext';

const SortBy = ({ sortingOption, setSortingOption }) => {
  const { isMobile } = useContext(AppContext);
  const isSelected = (sortBy) => sortBy === sortingOption;

  return (
    <Container>
      <TabWrapper>
        <SortTab
          name={'Relevance'}
          callback={() => setSortingOption(SORTING_OPTIONS.BEST_MATCH)}
          active={isSelected(SORTING_OPTIONS.BEST_MATCH)}
          tooltip={'Best match for search query'}
          disableTooltip={isMobile}
          isMobile={isMobile}
        />
        <SortTab
          name={'Date'}
          callback={() => setSortingOption(SORTING_OPTIONS.DATE)}
          active={isSelected(SORTING_OPTIONS.DATE)}
          tooltip={'Most recent results first'}
          disableTooltip={isMobile}
          isMobile={isMobile}
        />
      </TabWrapper>
    </Container>
  );
};

SortBy.propTypes = {
  sortingOption: PropTypes.string.isRequired,
  setSortingOption: PropTypes.func.isRequired,
};

const TabWrapper = styled.div`
  display: flex;
  font-family: 'Lab Grotesque', sans-serif;
  line-height: 1.4;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  font-style: normal;
  color: ${({ active }) => (!active ? '#777777' : 'black')};
`;

const Container = styled.div`
  display: inline-block;
  float: right;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const SortTab = styled(Tab)`
  flex-grow: 1;
  text-align: center;
  ${({ isMobile }) =>
    isMobile
      ? `
    border-color: black !important;
  `
      : ``}
`;

const mapStateToProps = ({ sortingOption }) => ({
  sortingOption,
});

export default connect(mapStateToProps, { setSortingOption: setSorting })(SortBy);
