import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const get = (highlightObj) => {
  if (!highlightObj) return '';
  if (Array.isArray(highlightObj)) return highlightObj.length > 0 ? highlightObj[0] : '';
  if (typeof highlightObj === 'object') return '';
  return highlightObj.toString();
};

const hasSnippet = (highlights) => {
  return highlights && ('summary' in highlights || 'body' in highlights);
};

const getSnippet = (hl) => {
  return !get(hl.title) && !get(hl.summary) && get(hl.body) ? get(hl.body) : get(hl.summary);
};

class Summary extends React.Component {
  render() {
    const { result, attributeName } = this.props;
    const attr = !result[attributeName] && attributeName === 'deck' ? 'blurb' : attributeName;
    const deck = result[attr];
    const hl = result.highlight || {};

    // the highlighted deck is called summary
    const snippet = hasSnippet(hl) ? getSnippet(hl) : deck;

    if (!snippet) return null;

    return (
      <Snippet>
        <div dangerouslySetInnerHTML={{ __html: snippet }} />
      </Snippet>
    );
  }
}

Summary.propTypes = {
  result: PropTypes.object.isRequired,
  attributeName: PropTypes.string.isRequired,
};

const Snippet = styled.p`
  font-family: 'Lab Grotesque', sans-serif;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 200;
  font-style: normal;
  color: gray;
  font-size: 16px;
  em {
    font-weight: 700;
    font-style: normal;
    color: #111;
  }
`;

export default Summary;
