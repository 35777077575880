import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

class Source extends React.Component {
  getSourceString = () => this.props.source && this.props.source.join('; ');

  render() {
    const source = this.getSourceString();

    if (!source) {
      return null;
    }

    return (
      <Container>
        Source: <Bold>{source}</Bold>
      </Container>
    );
  }
}

const Container = styled.div`
  font-family: 'Lab Grotesque', sans-serif;
  line-height: 1.4;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 200;
  font-style: normal;
  margin-left: 0;
  font-size: 12px;
  color: #777;
  margin-bottom: 5px;
  display: inline-block;
  float: right;
`;

const Bold = styled.span`
  font-weight: 700;
`;

Source.propTypes = {
  source: PropTypes.any,
};

export default Source;
