import { unset, isEmpty } from 'lodash/fp';
import qs from 'qs';
import * as AT from '../constants/action-types';
import config from '../config';
import { buildSearchQuery } from '../utils/query';
import { trackPage } from '../utils/analytics';
import { computeAdTopics } from '../utils/ads';
import { RESULTS_PER_PAGE } from '../constants/query';

const invokeAnalyticsCall = (state) => {
  if (!state.searchResults.numberOfResults) {
    trackPage({ NoResultsTerms: 'No Results: ' + state.currentSearchQuery });
    return;
  }

  const hierarchicalMenu = unset('type', state.selectedFacetsFilters);
  const published_date = state.selectedNumericFilters.published_date
    ? `${state.selectedNumericFilters.published_date.from}:${state.selectedNumericFilters.published_date.to}`
    : {};
  const refinementList = state.selectedFacetsFilters.type ? { type: state.selectedFacetsFilters.type } : {};

  let searchState = {
    hitsPerPage: RESULTS_PER_PAGE,
    sortBy: state.sortingOption,
    refinementList,
    multiRange: {
      published_date,
    },
    hierarchicalMenu,
  };

  if (isEmpty(hierarchicalMenu)) {
    searchState = unset('hierarchicalMenu', searchState);
  }

  if (isEmpty(refinementList)) {
    searchState = unset('refinementList', searchState);
  }

  if (isEmpty(searchState.multiRange.published_date)) {
    searchState = unset('multiRange', searchState);
  }

  trackPage({ SearchTerm: state.currentSearchQuery, SingleDimension: qs.stringify(searchState) });
};

const searchQueryMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);
    if (action.type !== AT.NEW_SEARCH_QUERY) {
      return;
    }
    if (typeof window !== 'object') {
      return;
    }
    const state = getState();
    const query = state.currentSearchQuery;
    const facetsFilters = state.selectedFacetsFilters;
    const numericFilters = state.selectedNumericFilters;
    const sortingOption = state.sortingOption;
    const page = state.page;
    buildSearchQuery(query, facetsFilters, numericFilters, sortingOption, page);
    invokeAnalyticsCall(state);

    if (config.ADS_ENABLED) {
      computeAdTopics(dispatch, query, facetsFilters);
    }
  };

export default searchQueryMiddleware;
