import React from 'react';
import PropTypes from 'prop-types';
import Type from '../../../../components/search-results/result/sub-components/type';
import Title from '../../../../components/search-results/result/sub-components/title';
import PubDate from '../../../../components/search-results/result/sub-components/pub-date';
import ResultContainer from '../../../../components/search-results/result/result-types/result-container';
import Summary from '../../../../components/search-results/result/sub-components/summary';

class Article extends React.Component {
  render() {
    const { result } = this.props;

    return (
      <ResultContainer>
        <Title result={result} title={result.title} type={result.type} />
        <Summary result={result} attributeName="deck" />
        <Type type={result.type} />
        &nbsp;|&nbsp;
        <PubDate date={result.published_date} />
      </ResultContainer>
    );
  }
}

Article.propTypes = {
  result: PropTypes.object.isRequired,
};

export default Article;
