import mobileDetect from 'mobile-detect';
import { getFacetValueName } from '../utils/facets';
import { FILTERS } from '../constants/facets';
import { someWithKey } from '../utils/general';
import config from '../config';
import { RESULTS_PER_PAGE } from '../constants/query';

export function delayNavigationForAnalytics(e) {
  e.preventDefault();

  const md = new mobileDetect(window.navigator.userAgent);
  const delay = md.mobile() ? 700 : 500;
  const location = e.currentTarget.href;

  setTimeout(
    () => {
      document.location.href = location;
    },
    delay,
    location
  );
}

export function trackEvent(event) {
  if (typeof window !== 'undefined' && window.iiAna) {
    window.iiAna('track', event);
  }
}

export function trackPage(pageData) {
  if (typeof window !== 'undefined' && window.iiAna) {
    window.iiAna('page', pageData);
  }
}

export function constructSearchAnalyticsEventData(state) {
  var searchData = {
    engine: {
      name: 'ELASTICSEARCH',
      v: config.QUERY_VERSION,
      ix: state.searchResults.indexVersion,
    },
    searchId: state.searchId,
    source: {
      app: state.source.app,
      location: state.source.location,
    },
    query: {
      keyword: state.currentSearchQuery,
      filters: {
        geo: getFacetAnalyticsValue(state.selectedFacetsFilters, FILTERS.GEOGRAPHIES),
        assetType: state.selectedFacetsFilters.type || [],
        topic: getFacetAnalyticsValue(state.selectedFacetsFilters, FILTERS.TOPICS),
        industry: getFacetAnalyticsValue(state.selectedFacetsFilters, FILTERS.INDUSTRIES),
        demographic: getFacetAnalyticsValue(state.selectedFacetsFilters, FILTERS.DEMOGRAPHICS),
        publicationDate: state.selectedNumericFilters.published_date
          ? state.selectedNumericFilters.published_date.displayString
          : 'All Publication Dates',
      },
      sort: state.sortingOption,
      size: RESULTS_PER_PAGE,
    },
    pageIndex: state.page,
    totalHits: state.searchResults.numberOfResults,
    results: makeAssetIds(state.searchResults.results),
    resultLabels: makeAssetLabels(state.searchResults.results),
    click: null,
    preview: null,
  };

  return searchData;
}

const getMonthAndYear = (epochSeconds) => {
  const date = new Date(epochSeconds * 1000);
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  return `${months[date.getMonth()]} ${date.getFullYear()}`;
};

const truncate = (str, max = 10) => {
  if (!str) {
    return '';
  }
  const array = str.trim().split(' ');
  const ellipsis = array.length > max ? '...' : '';

  return array.slice(0, max).join(' ') + ellipsis;
};

export const makeAssetIds = (assets) => {
  // Filter out null objectIDs added by ads in logged out SERP
  return assets.filter((a) => !isAd(a)).map((a) => a.objectID);
};

export const makeAssetLabels = (assets) => {
  // Filter out null objectIDs added by ads in logged out SERP
  return assets.filter((a) => !isAd(a)).map((a) => makeAssetLabel(a));
};

const makeAssetLabel = (asset) => {
  // First 5 words of title... (Type, Mon YYYY)
  return `${truncate(asset.title, 5)} (${asset.type}, ${getMonthAndYear(asset.published_date)})`;
};

const isAd = (asset) => {
  return asset.type === 'TextAd' || asset.type === 'Ad';
};

const getFacetAnalyticsValue = (facetFilters, facetType) => {
  let defaultFacetAnalyticsValue = '';

  if (facetFilters !== undefined) {
    let facetTypeName;

    let hasFacetTypeFilter = someWithKey((filterValue, filterName) => {
      const isIncluding = filterName.includes(facetType);

      if (isIncluding) {
        facetTypeName = filterName;
      }

      return isIncluding;
    }, facetFilters);

    if (hasFacetTypeFilter) {
      return getFacetValueName(facetFilters[facetTypeName]);
    }
  }

  return defaultFacetAnalyticsValue;
};
