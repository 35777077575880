export const insertAds = (searchResults) => {
  const firstAd = { id: 'TxtLink1', type: 'TextAd' };

  if (searchResults.length < 5) {
    searchResults.splice(2, 0, firstAd);
  } else {
    const secondAd = { id: 'Bottom1', type: 'Ad' };
    searchResults.splice(2, 0, firstAd);
    searchResults.splice(6, 0, secondAd);
  }

  return searchResults;
};
