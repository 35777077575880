import React, { useState, useRef } from 'react';
import TooltipTrigger from 'react-popper-tooltip';
import styled from 'styled-components';
import { useClickOutside } from '@insiderintelligence/componentlibrary';
import PropTypes from 'prop-types';

// import * as PopperJS from '@popperjs/core';

// interface ITooltip {
//   target: ReactNode;
//   placement: PopperJS.Placement;
//   skid?: number;
//   arrowSkid?: number;
//   distance?: number;
//   showArrow?: boolean;
// //   control show via external state, use when children controls parent tooltip
//   showPopper?: boolean;
//   controlShow?: boolean;
//   children?: ReactNode;
// }

const PopperTooltip = (props) => {
  const {
    target,
    placement,
    skid = 0,
    distance = 0,
    showArrow,
    controlShow,
    showPopper,
    children,
    onClickOutside,
    arrowClass,
  } = props;

  const [show, setShow] = useState(false);
  const ref = useRef(null);
  const innerTriggerRef = useRef(null);

  const showTooltip = controlShow ? showPopper : show;

  useClickOutside(ref, (e) => {
    if (innerTriggerRef.current && !innerTriggerRef.current.contains(e.target) && onClickOutside) onClickOutside();
  });

  return (
    <PopperWrapper>
      <TooltipTrigger
        placement={placement}
        tooltipShown={showTooltip}
        modifiers={[
          {
            name: 'offset',
            enabled: true,
            options: {
              offset: [skid, distance],
            },
          },
        ]}
        tooltip={({ arrowRef, tooltipRef, getArrowProps, getTooltipProps, placement }) => {
          return (
            <div
              {...getTooltipProps({
                ref: tooltipRef,
                className: 'popper-tooltip-wrapper',
              })}
            >
              <div className="popper-tooltip-animation">
                {showArrow && (
                  <div
                    {...getArrowProps({
                      ref: arrowRef,
                      className: `${arrowClass} popper-tooltip-arrow`,
                      'data-placement': placement,
                    })}
                  />
                )}
                <div ref={ref} className="popper-tooltip-container">
                  {children}
                </div>
              </div>
            </div>
          );
        }}
      >
        {({ getTriggerProps, triggerRef }) =>
          !controlShow ? (
            <div
              {...getTriggerProps({
                ref: triggerRef,
              })}
              onMouseEnter={() => setShow(true)}
              onMouseLeave={() => setShow(false)}
            >
              <div ref={innerTriggerRef}>{target}</div>
            </div>
          ) : (
            <div
              {...getTriggerProps({
                ref: triggerRef,
              })}
            >
              <div ref={innerTriggerRef}>{target}</div>
            </div>
          )
        }
      </TooltipTrigger>
    </PopperWrapper>
  );
};

const PopperWrapper = styled.div``;

export default PopperTooltip;

PopperTooltip.propTypes = {
  target: PropTypes.any,
  placement: PropTypes.any,
  skid: PropTypes.any,
  distance: PropTypes.any,
  showArrow: PropTypes.any,
  controlShow: PropTypes.any,
  showPopper: PropTypes.any,
  children: PropTypes.any,
  onClickOutside: PropTypes.any,
  arrowClass: PropTypes.any,
};
