import { handleActions } from 'redux-actions';

import * as AT from '../constants/action-types';

const initialState = 1;

const pageReducer = handleActions(
  {
    [AT.SET_PAGE]: (state, { payload }) => payload.page,

    [AT.SET_INITIAL_SEARCH_QUERY_FROM_URL_PARAMS]: (state, { payload }) => payload.page || initialState,
  },
  initialState
);

export default pageReducer;
