import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { words } from 'lodash/fp';
import Type from '../../../../components/search-results/result/sub-components/type';
import Title from '../../../../components/search-results/result/sub-components/title';
import Source from '../../../../components/search-results/result/sub-components/source';
import PubDate from '../../../../components/search-results/result/sub-components/pub-date';
import ResultContainer from '../../../../components/search-results/result/result-types/result-container';
import { VIEW_ASSET } from '../../../../constants/action-types';

import { delayNavigationForAnalytics } from '../../../../utils/analytics';

//import config from '../../../../config';

class Numbers extends React.Component {
  onKeyDownHandler = (e, templateId) => {
    if (e.keyCode === 13) {
      this.props.assetViewed(templateId);
      delayNavigationForAnalytics(e);
    }
  };

  onContextMenuHandler = (templateId) => {
    this.props.assetViewed(templateId);
  };

  onMouseDownHandler = (e, templateId) => {
    // ignore right clicks, handled by onContextMenu
    if (e.which === 3 || e.button === 2) return;

    this.props.assetViewed(templateId);
    delayNavigationForAnalytics(e);
  };

  getTemplateElement = (template) => (
    <Template key={template.Id}>
      <a
        href={`${this.props.result.url}/${template.Id}`}
        onMouseDown={(e) => this.onMouseDownHandler(e, template.Id)}
        onKeyDown={(e) => this.onKeyDownHandler(e, template.Id)}
        onContextMenu={this.onContextMenuHandler.bind(this, template.Id)}
        data-analytics={`Numbers-Template-${this.props.result.objectID}/${template.Id}--QUERY:${this.props.searchQuery}`}
      >
        {template.Title}
      </a>
    </Template>
  );

  getTemplates = () => {
    let templates = this.props.result.numbers_templates;

    const queryWords = words(this.props.searchQuery);
    let leftCol = [];
    let rightCol = [];

    if (queryWords.length) {
      templates = templates.filter((template) =>
        queryWords.some((word) => template.Title.toLowerCase().includes(word))
      );
    }

    templates.forEach((template, index) => {
      index % 2 === 1
        ? (rightCol = [...rightCol, this.getTemplateElement(template)])
        : (leftCol = [...leftCol, this.getTemplateElement(template)]);
    });

    if (leftCol.length < 1 && rightCol.length < 1) {
      return null;
    }

    return (
      <TemplatesContainer>
        <Column>{leftCol}</Column>
        <Column>{rightCol}</Column>
      </TemplatesContainer>
    );
  };

  render() {
    const { result } = this.props;

    return (
      <ResultContainer>
        <Title result={result} title={result.collection_title} type={result.type} />
        {this.getTemplates()}
        <Type type={result.type} />
        &nbsp;|&nbsp;
        <PubDate date={result.published_date} />
        <Source source={result.sources} />
      </ResultContainer>
    );
  }
}

const Column = styled.div`
  max-width: 41.666667%;
  width: 41.666667%;
  margin-left: 3%;
  display: inline-block;
  padding: 0;
  vertical-align: text-top;

  @media (max-width: 767px) {
    max-width: 91.666667%;
    width: 91.666667%;
  }
`;

const Template = styled.div`
  font-family: 'Lab Grotesque', sans-serif;
  line-height: 1.4;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 200;
  font-style: normal;

  font-size: 16px;

  a {
    color: ${({ theme }) => theme.title};
  }

  a:hover {
    color: ${({ theme }) => theme.titleHover};
  }
`;

const TemplatesContainer = styled.div`
  margin-top: 5px;
  font-family: 'Lab Grotesque', sans-serif;
  line-height: 1.4;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  font-style: normal;
  width: 100%;
`;

Numbers.propTypes = {
  result: PropTypes.object.isRequired,
  searchQuery: PropTypes.string.isRequired,
  assetViewed: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  searchQuery: state.submittedSearchQuery,
});

const mapDispatchToProps = (dispatch) => {
  return {
    assetViewed: (assetId) => dispatch({ type: VIEW_ASSET, payload: assetId }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Numbers);
