import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Type from '../../../../components/search-results/result/sub-components/type';
import Title from '../../../../components/search-results/result/sub-components/title';
import PubDate from '../../../../components/search-results/result/sub-components/pub-date';
import ResultContainer from '../../../../components/search-results/result/result-types/result-container';
import Name from '../../../../components/search-results/result/sub-components/name';
import PersonTitle from '../../../../components/search-results/result/sub-components/person-title';
import CompanyName from '../../../../components/search-results/result/sub-components/company-name';
import Summary from '../../../../components/search-results/result/sub-components/summary';

class Interview extends React.Component {
  render() {
    const { result } = this.props;

    return (
      <ResultContainer>
        <Title result={result} title={result.title} type={result.type} />
        <Summary result={result} attributeName="blurb" />
        <IntervieweeWrapper>
          <Name name={result.interviewee_name} />
          <PersonTitle title={result.interviewee_title} />
          <CompanyName name={result.interview_company} />
        </IntervieweeWrapper>
        <Type type={result.type} />
        &nbsp;|&nbsp;
        <PubDate date={result.published_date} />
      </ResultContainer>
    );
  }
}

const IntervieweeWrapper = styled.div`
  margin-top: 10px;
`;

Interview.propTypes = {
  result: PropTypes.object.isRequired,
};

export default Interview;
