import { handleActions } from 'redux-actions';
import * as AT from '../constants/action-types';

const initialState = '';

const searchIdReducer = handleActions(
  {
    [AT.SET_SEARCH_ID]: (state, { payload }) => payload.searchId,
  },
  initialState
);

export default searchIdReducer;
