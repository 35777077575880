import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';

import TopBarFilterTabs from '../components/search-results/topbar-filters-tabs';
import FacetFilterTabs from '../components/search-results/facet-filters-tabs';
import SortBy from '../components/search-results/results/sort-by';
import { AppContext } from './AppContext';

const TopBar = (props) => {
  const { scrolled } = useContext(AppContext);
  const { searchResults, query } = props;
  return (
    <Wrapper scrolled={scrolled}>
      {scrolled ? null : (
        <NumberOfResults>
          <Bold>{searchResults.numberOfResults}</Bold>
          {` results${query ? ` for ` : ''}`}
          <Bold>{query ? query : ''}</Bold>
        </NumberOfResults>
      )}
      <TopBarFilterTabs />
      <FooterWrapper>
        <FacetFilterTabs />
        <SortBy />
      </FooterWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  max-width: 1015px;
  min-height: ${({ scrolled }) => (scrolled ? '150px' : '195px')};
  margin: auto;
  padding: 30px 0;

  @media (max-width: 768px) {
    padding: 30px;
    min-height: 131px;
    padding: 30px 0px;
  }
`;

const NumberOfResults = styled.div`
  padding-bottom: 42px;
  font-size: 16px;
  color: #2c2c2c;
  display: inline-block;
  font-family: 'Lab Grotesque', sans-serif;
  line-height: 1.4;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  font-style: normal;

  @media (max-width: 768px) {
    font-size: 16px;
    display: none;
  }
`;

const Bold = styled.span`
  font-weight: 700;
`;

const FooterWrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
`;

TopBar.propTypes = {
  searchResults: PropTypes.shape({
    numberOfResults: PropTypes.number,
    // results: PropTypes.array
  }).isRequired,
  query: PropTypes.string,
};

const mapStateToProps = ({ searchResults, selectedNumericFilters, selectedFacetsFilters }) => ({
  searchResults,
  selectedNumericFilters,
  selectedFacetsFilters,
});

export default connect(mapStateToProps)(TopBar);
