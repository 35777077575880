import pino from 'pino';

const logger = pino({
  level: process.env.CURRENT_ENVIRONMENT === 'production' ? 'info' : 'debug',
  formatters: {
    level: (label) => {
      return {
        level: label,
      };
    },
  },
});

export default logger;
