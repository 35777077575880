import config from '../config';
import { upgradeQuery } from '../utils/queryUpgrader';

let elasticSearchClient = null;

const initializeElasticSearchClient = () => {
  return import('../emarketer-elastic-search').then((eMarketerElasticsearch) => {
    if (elasticSearchClient == null) {
      elasticSearchClient = new eMarketerElasticsearch.default({
        searchHost: config.ELASTICSEARCH_URI_READ,
        searchApp: config.SEARCH_APP_NAME,
        searchIndex: config.SEARCH_INDEX_NAME,
        searchMajorVersion: config.SEARCH_MAJOR_VERSION,
        searchTemplate: config.SEARCH_TEMPLATE_NAME,
      });
    }

    return elasticSearchClient;
  });
};

export const getSearchProvider = () => initializeElasticSearchClient();

export const setSearchQueryFromUrl = (urlQuery) => {
  return upgradeQuery(urlQuery);
};

export function getQueryParameterByName(name, url) {
  if (!url) url = window.location.href;
  name = name.replace(/[[\]]/g, '\\$&');
  const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
  const results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}
