import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import ResultContainer from '../../../../components/search-results/result/result-types/result-container';
import { trackEvent } from '../../../../utils/analytics';

class Ad extends React.Component {
  handleOnClick = () => {
    trackEvent(`Ad-Bottom1`);
  };

  render() {
    return (
      <ResultContainer onClick={this.handleOnClick}>
        <ContainerImage dangerouslySetInnerHTML={{ __html: this.props.ad }} />
      </ResultContainer>
    );
  }
}

const ContainerImage = styled.div`
  width: 100%;
  a{
    height:0;
    display block;
    }
    a:first-child{
    height:auto;
    img {
        height: auto;
        width: 100%;
  }
`;

Ad.propTypes = {
  ad: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    ad: state.ads.adUnits['Bottom1'],
  };
};

export default connect(mapStateToProps, null)(Ad);
