import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Checkbox, { CHECKED, UNCHECKED } from '../atoms/Checkbox';
import { FlexContainer } from '@insiderintelligence/componentlibrary';
import List from '../atoms/List';
import RadioButton from '../atoms/RadioButton';
import { trackEvent } from '../../../utils/analytics';

const DrilldownList = (props) => {
  const { label, callback, taxonomy, isMultiSelect, rootSelectable, defaultSelected = [], hideCounter } = props;

  // If isMultiSelect is flagged, we treat this is a normal
  // 1 dimensional array. We'll need to change this once
  // n-depth taxnomies can have multiple selections.
  const [stack, setStack] = useState(defaultSelected.filter((_, index) => index !== defaultSelected.length - 1));

  const selected = isMultiSelect
    ? defaultSelected
    : defaultSelected.length
    ? [defaultSelected[defaultSelected.length - 1]]
    : [];

  // We only need these 3 functions for n-depth taxonomies.
  // Seems like redux does the brunt work for us so we can
  // just take the path in redux and do simple object
  // orientation to find the node we need.
  const getNode = (path) => {
    if (!path.length) return taxonomy;
    let node = taxonomy;
    path.forEach((step) => {
      if (node[step]) {
        node = node[step].children;
      } else {
        node = null;
        return;
      }
    });
    return node;
  };

  const toChild = (e, child) => {
    e.stopPropagation();
    trackEvent(`Nav-Drilldown-${label}-${child}-Into`);
    setStack([...stack, child]);
    return;
  };

  const toParent = (e) => {
    e.stopPropagation();
    trackEvent(`Nav-Drilldown-${label}-${stack[stack.length - 1]}-Out`);
    setStack(stack.filter((_, index) => index !== stack.length - 1));
    return;
  };

  const node = isMultiSelect ? taxonomy : getNode(stack);

  const drilldowns = Object.entries(node).map(([key, value]) => {
    const input = isMultiSelect ? (
      <DrilldownCheckbox
        name={key}
        value={selected.includes(key) ? CHECKED : UNCHECKED}
        callback={() => callback(value.value)}
      />
    ) : (
      <DrilldownRadioButton
        groupName={label}
        value={selected.includes(key) ? CHECKED : UNCHECKED}
        callback={() => callback(value.value)}
      />
    );

    return (
      <Drilldown key={key} flexDirection="row" justifyContent="space-between">
        <DrilldownSelect flexDirection="row" spaceBetween={8}>
          {(rootSelectable || stack.length > 0) && input}
          <DrilldownLabel
            selectable={rootSelectable || stack.length > 0}
            onClick={() => {
              if (!selected.includes(key) && (rootSelectable || stack.length > 0)) callback(value.value);
            }}
          >
            {key}
          </DrilldownLabel>
          {value.counter && !hideCounter ? <DrilldownValue>{`(${value.counter})`}</DrilldownValue> : null}
        </DrilldownSelect>
        {value.children && Object.keys(value.children).length ? (
          <DrilldownIcon className="fa fa-chevron-right" onClick={(e) => toChild(e, key)} />
        ) : null}
      </Drilldown>
    );
  });

  return (
    <DrilldownWrapper>
      {
        <DrilldownBreadcrumb
          displayBreadcrumb={stack.length && !isMultiSelect}
          flexDirection="row"
          justifyContent="start"
          spaceBetween={10}
        >
          <DrilldownBreadcrumbIcon className="fa fa-chevron-left" onClick={(e) => toParent(e)} />
          <DrilldownBreadcrumbLabels alignItems="start">
            <DrilldownRoot>{label}</DrilldownRoot>
            <DrilldownParent>{stack[stack.length - 1]}</DrilldownParent>
          </DrilldownBreadcrumbLabels>
        </DrilldownBreadcrumb>
      }
      <DrilldownContainer spaceBetween={8}>{drilldowns}</DrilldownContainer>
    </DrilldownWrapper>
  );
};

DrilldownList.propTypes = {
  label: PropTypes.string.isRequired,
  callback: PropTypes.func.isRequired,
  taxonomy: PropTypes.object.isRequired,
  isMultiSelect: PropTypes.bool,
  rootSelectable: PropTypes.bool.isRequired,
  defaultSelected: PropTypes.any,
  hideCounter: PropTypes.bool,
};

const DrilldownWrapper = styled.div`
  font-weight: 400;
`;

const DrilldownBreadcrumb = styled(FlexContainer)`
  ${(p) =>
    !p.displayBreadcrumb
      ? `
    display: none;
  `
      : ''}
  width: 100%;
  margin-bottom: 20px;
`;

const DrilldownBreadcrumbIcon = styled.i`
  cursor: pointer;
  font-size: 14px;
`;

const DrilldownBreadcrumbLabels = styled(FlexContainer)``;

const DrilldownRoot = styled.span`
  color: #a4a4a4;
  font-size: 12px;
  line-height: 14px;
`;

const DrilldownParent = styled.span`
  font-size: 14px;
  font-weight: 900;
  line-height: 14px;
`;

const DrilldownContainer = styled(List)``;

const Drilldown = styled(FlexContainer)``;

const DrilldownSelect = styled(FlexContainer)``;

const DrilldownLabel = styled.span`
  cursor: ${({ selectable }) => (selectable ? 'pointer' : 'default')};
  font-size: 14px;
  font-weight: 900;
`;

const DrilldownValue = styled.span`
  color: #a4a4a4;
  font-size: 12px;
`;

const DrilldownIcon = styled.i`
  cursor: pointer;
  font-size: 14px;
  color: #007eff;
`;

const DrilldownCheckbox = styled(Checkbox)`
  width: 16px;
  height: 16px;
`;

const DrilldownRadioButton = styled(RadioButton)`
  width: 16px;
  height: 16px;
`;

export default DrilldownList;
