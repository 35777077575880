import qs from 'qs';
import { SORTING_OPTIONS } from '../constants/sorting-options';
import { RESULTS_PER_PAGE } from '../constants/query';

export const upgradeQuery = (locationQuery) => {
  let upgradedSearchObject = {
    query: '',
    facetsFilters: {},
    numericFilters: {},
    sortingOption: SORTING_OPTIONS.BEST_MATCH,
    resultsPerPage: RESULTS_PER_PAGE,
    page: 1,
    app: '',
    location: '',
  };

  if (locationQuery === '?' || locationQuery === '' || locationQuery === null || locationQuery === undefined) {
    return upgradedSearchObject;
  } // special cases
  let oldSearchObject = qs.parse(locationQuery.toLowerCase(), { ignoreQueryPrefix: true });

  if (oldSearchObject.multirange) {
    if (oldSearchObject.multirange.published_date) {
      const date = getDateRange(oldSearchObject.multirange.published_date);
      if (date) {
        upgradedSearchObject.numericFilters = date;
      }
    }
  }
  if (oldSearchObject.sortby) {
    if (Object.values(SORTING_OPTIONS).includes(oldSearchObject.sortby)) {
      upgradedSearchObject.sortingOption = oldSearchObject.sortby;

      // Legacy values coupled to Algolia which may have been saved with alerts
    } else if (['by_pub_date', 'dev_em_content_by_pub_date'].includes(oldSearchObject.sortby.toLowerCase())) {
      upgradedSearchObject.sortingOption = SORTING_OPTIONS.DATE;
    } else {
      upgradedSearchObject.sortingOption = SORTING_OPTIONS.BEST_MATCH;
    }
  } else {
    upgradedSearchObject.sortingOption = SORTING_OPTIONS.BEST_MATCH;
  }

  if (oldSearchObject.hierarchicalmenu) {
    let facetFilters = {};
    for (var menu in oldSearchObject.hierarchicalmenu) {
      if (menu.includes('.lvl') && oldSearchObject.hierarchicalmenu.hasOwnProperty(menu)) {
        if (oldSearchObject.hierarchicalmenu[menu]) {
          let lvl = oldSearchObject.hierarchicalmenu[menu].split('>').length - 1;
          facetFilters[`${menu.split('.')[0]}.lvl${lvl}`] = oldSearchObject.hierarchicalmenu[menu];
        }
      }
    }
    upgradedSearchObject.facetsFilters = facetFilters;
  }
  if (oldSearchObject.refinementlist) {
    // Copy selected facets into new structure
    const upgradedRefinements = Object.keys(oldSearchObject.refinementlist)
      .filter((key) => key !== 'type') // except type facets
      .reduce((upg, key) => {
        const refinements = oldSearchObject.refinementlist[key];
        const values = Array.isArray(refinements)
          ? oldSearchObject.refinementlist[key]
          : [oldSearchObject.refinementlist[key]];
        if (key in upg) {
          upg[key] = [...upg[key], ...values];
        } else {
          upg[key] = values;
        }
        return upg;
      }, {});

    // Special treatment for "type" (legacy 'numbers' searches)
    if (oldSearchObject.refinementlist.type) {
      typeof oldSearchObject.refinementlist.type !== typeof []
        ? (upgradedRefinements.type = [oldSearchObject.refinementlist.type])
        : (upgradedRefinements.type = oldSearchObject.refinementlist.type);

      var numbers = upgradedRefinements.type.find((x) => x.match(/numbers/i));
      if (numbers != null) {
        upgradedRefinements.type.push('Forecasts');
        upgradedRefinements.type = upgradedRefinements.type.filter((x) => x !== numbers);
      }
    }

    // Combine flat "refinement" filters with hierarchical filters from the previous block.
    upgradedSearchObject.facetsFilters = Object.assign(upgradedSearchObject.facetsFilters, upgradedRefinements);
  }

  if (oldSearchObject.query) {
    upgradedSearchObject.query = oldSearchObject.query;
  }

  try {
    if (oldSearchObject.page) {
      upgradedSearchObject.page = parseInt(oldSearchObject.page, 10);
    } else {
      upgradedSearchObject.page = 1;
    }
  } catch (e) {
    upgradedSearchObject.page = 1;
  }

  upgradedSearchObject.source = { app: '', location: '' };
  if (oldSearchObject.app) {
    upgradedSearchObject.source.app = oldSearchObject.app;
  }

  if (oldSearchObject.location) {
    upgradedSearchObject.source.location = oldSearchObject.location;
  }

  return upgradedSearchObject;
};

export const getDateRange = (dateRange) => {
  let filter = {};
  const range = dateRange.split(':');
  const displayString = figureOutDisplayString(range);
  if (displayString) {
    filter.published_date = { from: range[0], to: range[1], displayString: displayString };
    return filter;
  }
  return null;
};

export const figureOutDisplayString = (range) => {
  const difference = range[1] - range[0];
  if (difference <= 7870000) {
    return 'Within last 90 days';
  } else if (difference <= 15650000) {
    return 'Within last 180 days';
  } else if (difference <= 317200001) {
    return 'Within last year';
  } else {
    return null;
  }
};
