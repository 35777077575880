import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { getDisplayDateString } from '../../../../utils/time';

const PubDate = ({ date }) => {
  const getFormattedDate = () => {
    if (date) {
      if (!isNaN(parseFloat(date))) {
        let newDate = new Date(date * 1000);
        return getDisplayDateString(newDate);
      } else if (typeof date === 'string' && !isNaN(Date.parse(date))) {
        let newDate = new Date(date);
        return getDisplayDateString(newDate);
      }
    }

    return 'N/A';
  };

  return <Container>{getFormattedDate()}</Container>;
};

const Container = styled.div`
  font-family: 'Lab Grotesque', sans-serif;
  line-height: 22px;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 100;
  font-style: normal;
  font-size: 12px;
  color: #747474;
  margin-bottom: 5px;
  display: inline-block;
`;

PubDate.propTypes = {
  date: PropTypes.any,
};

export default PubDate;
