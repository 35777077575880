import config from '../config';
import { RESULT_TYPES, TITLE_IMAGE_POSITION } from '../constants/result';

/**
 * As of now, we are only restricting Annotations from public view
 * Indexes are also separated for this purpose
 *
 * @param type
 * @param audience
 */
const isVisible = (type, audience) => {
  if (['Annotation', 'Source', 'Research'].includes(type)) {
    return false;
  }
  return true;
};

export const visibleResultTypes = () => {
  return Object.keys(RESULT_TYPES)
    .filter((rt) => isVisible(rt, config.AUDIENCE)) // audience set globally at startup
    .reduce((map, rt) => Object.assign(map, { [rt]: RESULT_TYPES[rt] }), {});
};

export const getTitleImagePosition = (type) => TITLE_IMAGE_POSITION[type];

export const getResultTypeBlurb = (type) => {
  switch (type) {
    case 'Report':
      return 'report_summary';
    case 'Article':
      return 'deck';
    case 'Audio':
      return 'deck';
    case 'Video':
      return 'deck';
    case 'Annotation':
      return 'selection';
    case 'Source':
    case 'Research':
    case 'Interview':
    case 'Brief':
    case 'Webinar':
    default:
      return 'blurb';
  }
};
