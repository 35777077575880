import * as AT from '../constants/action-types';

export const selectNumericFilter = (selectedNumericFilter) => ({
  type: AT.SELECT_NUMERIC_FILTER,
  payload: {
    selectedNumericFilter,
  },
});

export const unselectNumericFilter = (numericFilterToRemove) => ({
  type: AT.UNSELECT_NUMERIC_FILTER,
  payload: {
    numericFilterToRemove,
  },
});
