import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';

import Results from '../components/search-results/results';
import SearchResultsPagination from '../components/search-results/search-results-pagination';
import RelatedQueries from '../components/related-queries/related-queries';
import { IdleUntilUrgent } from '../utils/idleUntilUrgent';
import config from '../config';
import { trackEvent } from 'utils/analytics';

const SearchResults = ({ searchResults }) => {
  useEffect(() => {
    if (searchResults.didYouMean) {
      trackEvent(`PublicSearch-DidYouMean-Visible`);
    }
  }, [searchResults.didYouMean]);

  return (
    <Container>
      {searchResults.didYouMean && (
        <DidYouMean>
          Did you mean{' '}
          <DidYouMeanTerm
            data-analytics="PublicSearch-DidYouMean-Click"
            href={`${encodeURI(`${config.SEARCH_BASE_URL}?query=${searchResults.didYouMean}`)}`}
          >
            {searchResults.didYouMean}
          </DidYouMeanTerm>
        </DidYouMean>
      )}
      {searchResults.numberOfResults && searchResults.numberOfResults >= 1 ? (
        <Results results={searchResults.results} />
      ) : (
        <NoResults>
          <Headline>Your search did not match any results.</Headline>
          <Subheadline>
            Please make sure all keywords are spelled correctly
            <br /> or try unselecting a few filters.
          </Subheadline>
        </NoResults>
      )}
      <IdleUntilUrgent htmlElement="div">
        <SearchResultsPagination />
      </IdleUntilUrgent>

      <IdleUntilUrgent htmlElement="div">
        <RelatedQueries />
      </IdleUntilUrgent>
    </Container>
  );
};

SearchResults.propTypes = {
  searchResults: PropTypes.shape({
    numberOfResults: PropTypes.number,
    didYouMean: PropTypes.string,
    results: PropTypes.array,
  }).isRequired,
};

const DidYouMean = styled.div`
  color: #111111;
  font-family: 'Lab Grotesque', sans-serif;
  line-height: 2.6;
  font-weight: 400;
  font-style: normal;
  font-size: 16px;
`;

const DidYouMeanTerm = styled.a`
  color: #007eff;
  font-weight: 700;
`;

const Container = styled.div`
  width: 100%;

  ul {
    list-style: none;
    display: flex;
    flex-direction: column;
  }
`;

const NoResults = styled.div`
  min-height: 300px;
  padding: 100px 50px;
  text-align: center;
  color: black;

  font-family: 'Lab Grotesque', sans-serif;
  line-height: 1.1;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  font-style: normal;
`;

const Headline = styled.div`
  font-size: 26px;
  font-weight: 900;
`;

const Subheadline = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: #2c2c2c;
  padding-top: 10px;
`;

const mapStateToProps = ({ searchResults }) => ({
  searchResults,
});

export default connect(mapStateToProps)(SearchResults);
