import { combineReducers } from 'redux';

import searchResultsReducer from '../reducers/search-results';
import facetsReducer from '../reducers/facets';
import selectedFacetsFiltersReducer from '../reducers/selected-facets-filters';
import selectedNumericFiltersReducer from '../reducers/selected-numeric-filters';
import currentSearchQueryReducer from '../reducers/current-search-query';
import submittedSearchQueryReducer from '../reducers/submitted-search-query';
import sortingOptionReducer from '../reducers/sorting-option';
import pageReducer from '../reducers/page';
import adsReducer from '../reducers/ads';
import searchIdReducer from '../reducers/search-id';
import sourceReducer from '../reducers/source';
import relatedQueriesReducer from '../reducers/related-queries';
import brandingSideRailReducer from '../reducers/branding-side-rail';

const rootReducer = combineReducers({
  selectedFacetsFilters: selectedFacetsFiltersReducer,
  selectedNumericFilters: selectedNumericFiltersReducer,
  searchResults: searchResultsReducer,
  facets: facetsReducer,
  currentSearchQuery: currentSearchQueryReducer,
  submittedSearchQuery: submittedSearchQueryReducer,
  sortingOption: sortingOptionReducer,
  page: pageReducer,
  ads: adsReducer,
  searchId: searchIdReducer,
  source: sourceReducer,
  relatedQueries: relatedQueriesReducer,
  brandingSideRail: brandingSideRailReducer,
});

export default rootReducer;
